import React, {useEffect, useState} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';

import Container from '@material-ui/core/Container';
import logo from '../../assets/images/logo.png'

import {useParams} from "react-router-dom";
import {useMutation} from "react-query";
import routes from "../../services/api";
import {startCloseLoading, startOpenLoading} from "../../redux/actions/ui";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {CircularProgress} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '30px',
        paddingTop: '50px!important'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
        fontSize: '16px',
        padding: '7px'
    },
}));


export default function CambiarEstadoFactura() {

    const [text,setText] = useState();
    const {estado,uniqid} = useParams();
    const dispatch = useDispatch();
    const classes = useStyles();

    let data = {
        estado : estado,
        uniqid : uniqid
    }

    const mutation_create_factura = useMutation((data) => routes.facturas.cambiar_estado(data), {
        onSuccess: (response) => {
            if(estado=='vista'){
            window.location.href ='http://api.facturacion.grepol2.es/api/facturas/factura_pdf/'+response.data.data;

            setTimeout(()=>{
                toast.success("Se ha descargado la factura en pdf.", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    hideProgressBar: true,
                    autoClose: 2000
                })

                setText("Se ha descargado la factura en pdf.")
            },1500)

                return
            }
            toast.success("La factura se ha marcado como "+estado+" correctamente", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })

            setText("La factura se ha marcado como "+estado+" correctamente")



        },
        onError: (error) => {

            toast.error("Error al realizar la acción", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })
            setText("Error al realizar la acción")


        },
        onMutate: () => {

        }
    })



    useEffect(()=>{

        if(estado==='aceptada' || estado==='rechazada' || estado==='vista') {
            mutation_create_factura.mutate(data)
        }else{
            setText('Acción incorrecta')
        }
    },[])

    return (
        <div className="Bg-Transition">

            <Container className="Login" component="main" maxWidth="md">
                <CssBaseline/>
                <div className={classes.paper}>
                    <img src={logo} alt="logo"/>

                    { text ?  <Typography component="h1" variant="h5">
                        {text}
                    </Typography> :
                    <CircularProgress/>}


                </div>

            </Container>
        </div>

    );
}