import React from 'react';
import {Page,  Grid} from "tabler-react";
import TablaPartidas from "./tabla-partidas";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import {Link as RouterLink} from "react-router-dom";
import {
    useParams
} from "react-router-dom";
import {getLocalStorageData} from "../../../helpers/auth-helpers";
function ArticulosPartidas(props) {
    let { id_capitulo } = useParams();



    return (
        <>
            <Page.Content title={'Artículos - Partidas del capítulo ' +  getLocalStorageData('capitulo_seleccionado_nombre') || ''}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit"  component={RouterLink} to="/">
                        Inicio
                    </Link>
                    <Link color="inherit"  component={RouterLink} to="/articulos-capitulos">
                        Artículos - Capítulos
                    </Link>


                    <Typography color="textPrimary"> Artículos - Partidas del capítulo {getLocalStorageData('capitulo_seleccionado_nombre') || ''}</Typography>

                </Breadcrumbs>
                <Grid.Row>
                    <Grid.Col className="Bg-white">

                        <TablaPartidas id_capitulo={id_capitulo} />
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </>

    );
}

export default ArticulosPartidas;