import React, {useEffect, useState} from 'react';

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Wysiwyg from "../../../commons/wysiwyg";
import '../../../../assets/forms-pagina.scss'
import {toast} from "react-toastify";
import {Controller, useFormContext} from "react-hook-form";
import {CircularProgress, MenuItem} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {justNumbers} from "../../../../helpers/auth-helpers";
import moment from "moment";
import {useQuery} from "react-query";
import routes from "../../../../services/api";

function CrearFacturaStep2(props) {

    const methods = useFormContext();
    const {reset, register, control, getValues} = methods;
    const [idiomaSeleccionado, setIdiomaSeleccionado] = useState()
    useEffect(() => {
        setIdiomaSeleccionado(getValues().idioma)

        reset({...props.dataForm}, {errors: true});
    }, []);

    useEffect(() => {
        if(idiomaSeleccionado) refetch_lista_capitulos()



    }, [idiomaSeleccionado]);



    // handle click event of the Remove button
    const handleRemoveClickLinea = (seccion_pos, linea_key) => {
        const list = [...props.sections];
        //console.log(list)
        props.setSections([])
        setTimeout(() => {
            list[seccion_pos].splice(linea_key, 1);
            props.setSections(list);
        },300)
        /* list[seccion_pos].splice(linea_key, 1);
         props.setSections(list);*/


        const listSeccionesCompletas = [...props.sectionsTitulos];

        for (var i = 0; i < listSeccionesCompletas.length; i++) {
            for (var e = 1; e < listSeccionesCompletas[i].length; e++) {


                let descripcion_actual = document.getElementById("[seccion]["+i+"][lineas]["+e+"].descripcion").value;
                let cantidad_actual = document.getElementById("[seccion]["+i+"][lineas]["+e+"].cantidad").value;
                let unidad_actual = document.getElementById("[seccion]["+i+"][lineas]["+e+"].unidad").nextElementSibling.value;
                let precio_actual = document.getElementById("[seccion]["+i+"][lineas]["+e+"].precio").value;
                console.log('cantidad '+unidad_actual)
                listSeccionesCompletas[i][e]=[{
                    descripcion: descripcion_actual,
                    precio: precio_actual,
                    cantidad: cantidad_actual,
                    unidad: unidad_actual,
                }]

            }
        }

        let slides = document.getElementsByClassName('titulosdecapitulos')
        let array_values = []
        for (var i = 0; i < slides.length; i++) {
            array_values.push(slides[i].value)
        }
        props.setSectionsTitulos([])
        setTimeout(() => {
            listSeccionesCompletas[seccion_pos].splice((linea_key), 1);
            props.setSectionsTitulos(listSeccionesCompletas)
        },300)
        /* listSeccionesCompletas[seccion_pos].splice((linea_key), 1);
         props.setSectionsTitulos(listSeccionesCompletas)*/

        setTimeout(() => {
            for (var i = 0; i < array_values.length; i++) {
                document.getElementById('[seccion]['+i+'].titulo').value = array_values[i]
            }
        },600)
    };

    const handleRemoveClickSection = index => {
        const list_sections = [...props.sections];
        list_sections.splice(index, 1);
        props.setSections(list_sections);

        const listSeccionesCompletas_sections = [...props.sectionsTitulos];
        listSeccionesCompletas_sections.splice(index, 1);
        props.setSectionsTitulos(listSeccionesCompletas_sections)
    };

    const handleRemoveClickSection2 = (seccion_pos) => {
        const list = [...props.sections];
        //console.log(list)
        props.setSections([])
        setTimeout(() => {
            list.splice(seccion_pos, 1);
            props.setSections(list);
        },300)
        /* list[seccion_pos].splice(linea_key, 1);
         props.setSections(list);*/


        const listSeccionesCompletas = [...props.sectionsTitulos];

        for (var i = 0; i < listSeccionesCompletas.length; i++) {
            for (var e = 1; e < listSeccionesCompletas[i].length; e++) {


                let descripcion_actual = document.getElementById("[seccion]["+i+"][lineas]["+e+"].descripcion").value;
                let cantidad_actual = document.getElementById("[seccion]["+i+"][lineas]["+e+"].cantidad").value;
                let unidad_actual = document.getElementById("[seccion]["+i+"][lineas]["+e+"].unidad").nextElementSibling.value;
                let precio_actual = document.getElementById("[seccion]["+i+"][lineas]["+e+"].precio").value;
                console.log('cantidad '+unidad_actual)
                listSeccionesCompletas[i][e]=[{
                    descripcion: descripcion_actual,
                    precio: precio_actual,
                    cantidad: cantidad_actual,
                    unidad: unidad_actual,
                }]

            }
        }


        let slides = document.getElementsByClassName('titulosdecapitulos')
        let array_values = []
        for (var i = 0; i < slides.length; i++) {
            array_values.push(slides[i].value)
        }
        array_values.splice((seccion_pos), 1)

        props.setSectionsTitulos([])
        setTimeout(() => {
            listSeccionesCompletas.splice((seccion_pos), 1);
            props.setSectionsTitulos(listSeccionesCompletas)
        },300)
        /* listSeccionesCompletas[seccion_pos].splice((linea_key), 1);
         props.setSectionsTitulos(listSeccionesCompletas)*/

        setTimeout(() => {

            for (var i = 0; i < array_values.length; i++) {
                document.getElementById('[seccion]['+i+'].titulo').value = array_values[i]
            }
        },700)
    };


    /*NUEVO PARTIDAS Y CAPITULOS*/


    const {
        status,
        data = [],
        error,
        isFetching: lista_capitulos_loading,
        refetch: refetch_lista_capitulos
    } = useQuery("getAllCapitulos", async () => {
        const res = await routes.articulo_capitulo.getAll(idiomaSeleccionado)
        return res.data;
    }, {refetchOnWindowFocus: false, enabled: false});

    const {
        data: data_partidas = [],
        isFetching: lista_partidas_loading,
        refetch: refetch_lista_partidas
    } = useQuery("getAllPartidas", async () => {
        const res = await routes.articulo_partida.getAll(idCapituloSeleccionado,idiomaSeleccionado)
        return res.data;
    }, {refetchOnWindowFocus: false, enabled: false});

    const {
        data: data_partida = [],
        refetch: refetch_partida
    } = useQuery("getPartida", async () => {
        const res = await routes.articulo_partida.get(idPartidaSeleccionada)
        return res.data;
    }, {refetchOnWindowFocus: false, enabled: false});


    let array_capitulos = []
    let array_partidas = []

    const [idCapituloSeleccionado, setidCapituloSeleccionado] = useState(0)
    const [idPartidaSeleccionada, setidPartidaSeleccionada] = useState(0)

    const buscarPartidas = data => {


        setidCapituloSeleccionado(data.target.value)
        /*refetch_lista_partidas(data.target.value)
*/
        /* if (data.target.value) {
             setidCapituloSeleccionado(data.target.value)
             let copy = [...sectionsTitulos];
             copy.push([array_capitulos[data.target.value]])
             setSectionsTitulos(copy)

             props.crearNuevaSeccion()

         }*/
    }

    function crearNuevaSeccionNuevo() {
        let copy = [...props.sectionsTitulos];
        copy.push(['hola'])
        props.setSectionsTitulos(copy)
        props.crearNuevaSeccion()
    }

    useEffect(() => {


        if (idCapituloSeleccionado !== 0) {
            refetch_lista_partidas()
        }
    }, [idCapituloSeleccionado])


    const crearPartidaConTextoPorDefecto = (seccion_key) => {
        if(!idPartidaSeleccionada || idPartidaSeleccionada ===0) {
            toast.error("No hay ningún artículo seleccionado ", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })
            return
        }
        refetch_partida().then((res) => {

            let copy = [...props.sectionsTitulos];
            console.log(copy)
            copy[seccion_key].push([{
                descripcion: res.data.descripcion,
                precio: res.data.precio,
                unidad: res.data.unidad,
            }])
            props.setSectionsTitulos(copy)
            /* console.log(sectionsTitulos[0][1][0].descripcion)*/

            props.crearNuevaLinea(seccion_key)
        })


    }
    const crearPartidaConTextoPorDefectoVacio = (seccion_key) => {


        let copy = [...props.sectionsTitulos];

        copy[seccion_key].push([{
            descripcion: '',
            precio: 1,
            unidad: 'unidades',
        }])
        props.setSectionsTitulos(copy)
        /* console.log(sectionsTitulos[0][1][0].descripcion)*/

        props.crearNuevaLinea(seccion_key)


    }
    /*------------------*/


    return (
        <>
            <div className="Row-flex ">

                <FormControl fullWidth className="w-100">
                    <InputLabel>
                        Quieres que aparezca el importe total al final de cada capítulo?
                    </InputLabel>

                    <Controller
                        control={control}
                        name="importe_total_por_capitulo"
                        className="w-50"
                        defaultValue="no"
                        as={
                            <Select>
                                <MenuItem value="no">No</MenuItem>
                                <MenuItem value="si">Sí</MenuItem>
                            </Select>
                        }
                    />
                </FormControl>
                {/*ELEGIR CAPITULO Y PARTIDA ANTIGUO*/}
               {/* <div className="d-flex flex-column">

                    NUEVO PARTIDAS

                    <FormControl fullWidth className="mb-2">
                        {lista_capitulos_loading
                            ?
                            <CircularProgress/>
                            :
                            <>
                                <InputLabel>
                                    Elegir capítulo
                                </InputLabel>

                                <Controller
                                    control={control}
                                    name="capitulo_pre"
                                    defaultValue={""}

                                    render={() => (
                                        <Select onChange={buscarPartidas} defaultValue={""}>
                                            <MenuItem value="">Seleccionar</MenuItem>
                                            {data.map((item, i) => {


                                                    array_capitulos[item.id] = item.descripcion

                                                    return (
                                                        <MenuItem key={i}
                                                                  value={item.id}>{item.descripcion}</MenuItem>
                                                    )
                                                }
                                            )}

                                        </Select>
                                    )}
                                /> </>}
                    </FormControl>
                    NUEVO PARTIDAS

                    {
                        idCapituloSeleccionado > 0 && (
                            <FormControl className="ml-auto w-100 mt-5">
                                {lista_partidas_loading
                                    ?
                                    <CircularProgress/>
                                    :
                                    <>
                                        <InputLabel>
                                            Elegir Partida
                                        </InputLabel>

                                        <Controller
                                            control={control}
                                            name="descripcion_pre"
                                            defaultValue={""}
                                            id="partida_seleccionada"

                                            render={() => (
                                                <Select onChange={(e) => setidPartidaSeleccionada(e.target.value)}
                                                        defaultValue={""}>
                                                    <MenuItem value="">Seleccionar</MenuItem>
                                                    {data_partidas.map((item, i) => {

                                                            array_partidas[item.id] = {
                                                                descripcion: item.descripcion,
                                                                precio: item.precio,
                                                                unidad: item.unidad
                                                            }
                                                            return (
                                                                <MenuItem key={i}
                                                                          value={item.id}>{item.descripcion}</MenuItem>)
                                                        }
                                                    )
                                                    }

                                                </Select>
                                            )}
                                        />
                                    </>
                                }
                            </FormControl>

                        )
                    }
                </div> */}
                {/* END ELEGIR CAPITULO Y PARTIDA ANTIGUO*/}

                {/*ELEGIR PARTIDA Y CAPITULO FIXED TOP*/}
                <div className="Elegir-partida-fixed-top">


                    <FormControl  className="mb-2 w-25 mr-5">
                        {lista_capitulos_loading
                            ?
                            <CircularProgress/>
                            :
                            <>
                                <InputLabel>
                                    Elegir Artículo Capítulo
                                </InputLabel>

                                <Controller
                                    control={control}
                                    name="capitulo_pre"
                                    defaultValue={""}

                                    render={() => (
                                        <Select onChange={buscarPartidas} defaultValue={""}>
                                            <MenuItem value="">Seleccionar</MenuItem>
                                            {data.map((item, i) => {


                                                    array_capitulos[item.id] = item.descripcion

                                                    return (
                                                        <MenuItem key={i}
                                                                  value={item.id}>{item.descripcion}</MenuItem>
                                                    )
                                                }
                                            )}

                                        </Select>
                                    )}
                                />
                                </>}
                    </FormControl>

                    {
                        idCapituloSeleccionado > 0 && (
                            <FormControl className="ml-auto w-50 ml-5 ">
                                {lista_partidas_loading
                                    ?
                                    <CircularProgress/>
                                    :
                                    <>
                                        <InputLabel>
                                            Elegir Artículo Partida
                                        </InputLabel>

                                        <Controller
                                            control={control}
                                            name="descripcion_pre"
                                            defaultValue={""}
                                            id="partida_seleccionada"

                                            render={() => (
                                                <Select onChange={(e) => setidPartidaSeleccionada(e.target.value)}
                                                        defaultValue={""}>
                                                    <MenuItem value="">Seleccionar</MenuItem>
                                                    {data_partidas.map((item, i) => {

                                                            array_partidas[item.id] = {
                                                                descripcion: item.descripcion,
                                                                precio: item.precio,
                                                                unidad: item.unidad
                                                            }
                                                            return (
                                                                <MenuItem key={i}
                                                                          value={item.id}>{item.descripcion}</MenuItem>)
                                                        }
                                                    )
                                                    }

                                                </Select>
                                            )}
                                        />
                                    </>
                                }
                            </FormControl>

                        )
                    }
                </div>

                {/*END ELEGIR PARTIDA Y CAPITULO FIXED TOP*/}
                {/*-----------*/}
                {/*---*/}
            </div>

            <br/>
            <hr/>


            {
                props.sectionsTitulos.map((item, key) => {
                    return (
                        <div key={key} className="">

                            <div className="d-flex align-items-center">
                                <TextField name={`[seccion][${key}].titulo`} id={`[seccion][${key}].titulo`} inputRef={register} autoFocus
                                           defaultValue={""}
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           inputProps={{ "className": "titulosdecapitulos"}}
                                           fullWidth className="mr-2" style={{width: '50%'}}
                                           margin="normal" label={'Título capítulo'}
                                           required/>

                            </div>

                            <br/>
                          {/*  <div className="d-flex justify-content-between">
                                <Button type="button" variant="contained" className="Boton-borrar"
                                        style={{margin: '0 0 0 0!important'}}
                                        onClick={() => handleRemoveClickSection(key)}>Eliminar este capítulo</Button>
                                <div>

                                    <Button type="button" variant="contained" className=" mr-3"
                                            style={{backgroundColor: '#303f9f', margin: '0 0 0 0!important'}}
                                            onClick={() => crearPartidaConTextoPorDefectoVacio(key)}>Añadir partida
                                        vacía</Button>

                                    <Button type="button" variant="contained" className=" mr-3"
                                            style={{backgroundColor: '#303f9f', margin: '0 0 0 0!important'}}
                                            onClick={() => crearPartidaConTextoPorDefecto(key)}>Añadir partida
                                        seleccionada a este capítulo</Button>
                                </div>

                            </div>
                            <br/>*/}
                            {
                                props.sectionsTitulos[key].map((item, key2) => {
                                    let numberprekey = '0'
                                    if (key2 >= 9) numberprekey = ''
                                    if(item!='hola') {
                                        return (
                                            <div key={key2} className="Row-Grid-facturas">
                                                <div className="d-flex">
                                                    <TextField name={`[seccion][${key}][lineas][${key2}].partida`}
                                                               inputRef={register} autoFocus
                                                               defaultValue={`${key + 1}.${numberprekey + (key2)}`}
                                                               fullWidth className="mr-2"
                                                               margin="normal" label={'Partida'}
                                                               required/>
                                                </div>
                                                <div>

                                                    {/*<TextField name={`description`} inputRef=register
                                           value={item.description} autoFocus fullWidth
                                           margin="normal" label={'Descripción:'} multiline
                                           rowsMax={4}
                                           required/>*/}
                                                    {/*  <Controller
                                                    as={<Wysiwyg  valueInicial={ props.dataForm.seccion &&  props.dataForm.seccion[key] && props.dataForm.seccion[key].lineas && props.dataForm.seccion[key].lineas[key2] ? props.dataForm.seccion[key].lineas[key2].descripcion : ''}/>}
                                                    defaultValue={props.dataForm.seccion &&  props.dataForm.seccion[key] && props.dataForm.seccion[key].lineas && props.dataForm.seccion[key].lineas[key2] ? props.dataForm.seccion[key].lineas[key2].descripcion : ''}
                                                    name={`[seccion][${key}][lineas][${key2}].descripcion`}
                                                    control={control}
                                                />*/}
                                                    <TextField name={`[seccion][${key}][lineas][${key2}].descripcion`}
                                                               id={`[seccion][${key}][lineas][${key2}].descripcion`}
                                                               inputRef={register}
                                                               defaultValue={props.sectionsTitulos[key] && props.sectionsTitulos[key][key2] && props.sectionsTitulos[key][key2][0].descripcion || ''}
                                                               fullWidth
                                                               margin="normal" label={'Descripción:'} multiline

                                                               required/>

                                                </div>


                                                <div className="d-flex">


                                                    <Controller
                                                        name={`[seccion][${key}][lineas][${key2}].cantidad`}
                                                        defaultValue={props.sectionsTitulos[key] && props.sectionsTitulos[key][key2] && props.sectionsTitulos[key][key2][0].cantidad || 1}
                                                        render={(props) => (
                                                            < TextField   {...props} type="tel"
                                                                          fullWidth className="mr-2"
                                                                          id={`[seccion][${key}][lineas][${key2}].cantidad`}
                                                                          margin="normal" label={'Cantidad'}
                                                                /* onChange={(e) => {
                                                                     /!*             props.onChange(parseInt(e.target.value, 10)); *!/
                                                                     /!*if (e.target.value) {
                                                                         props.onChange(justNumbers(e.target.value));
                                                                     }*!/


                                                                 }}*/
                                                            />
                                                            /* <input
                                                                 type="tel"
                                                                 {...props}
                                                                 onChange={(e) => {
                                                                     props.onChange(parseInt(e.target.value, 10));
                                                                 }}
                                                             />*/
                                                        )}
                                                        control={control}
                                                    />
                                                    <FormControl fullWidth>
                                                        <InputLabel>
                                                            Unidad
                                                        </InputLabel>
                                                        <Controller
                                                            control={control}
                                                            name={`[seccion][${key}][lineas][${key2}].unidad`}
                                                            id={`[seccion][${key}][lineas][${key2}].unidad`}
                                                            defaultValue={props.sectionsTitulos[key] && props.sectionsTitulos[key][key2] && props.sectionsTitulos[key][key2][0].unidad || 'unidades'}

                                                            as={
                                                                <Select>
                                                                    <MenuItem value="">Seleccionar</MenuItem>

                                                                    <MenuItem value="unidades">
                                                                        Unidades
                                                                    </MenuItem>
                                                                    <MenuItem value="P.A">
                                                                        P.A
                                                                    </MenuItem>
                                                                    <MenuItem value="Ml">
                                                                        Ml
                                                                    </MenuItem>
                                                                    <MenuItem value="m2">
                                                                        m2
                                                                    </MenuItem>
                                                                    <MenuItem value="m3">
                                                                        m3
                                                                    </MenuItem>

                                                                </Select>
                                                            }
                                                        />


                                                    </FormControl>
                                                </div>


                                                <div className="d-flex position-relative">

                                                    {/*   <TextField  inputRef={register}
                                                                name={`[seccion][${key}][lineas][${key2}].precio`}
                                                                autoFocus fullWidth type="tel"
                                                                margin="normal" label={'Precio €'}
                                                                required/>*/}
                                                    <Controller
                                                        name={`[seccion][${key}][lineas][${key2}].precio`}

                                                        defaultValue={props.sectionsTitulos[key] && props.sectionsTitulos[key][key2] && props.sectionsTitulos[key][key2][0].precio || 1}

                                                        render={(props) => (
                                                            <TextField
                                                                {...props}
                                                                id={`[seccion][${key}][lineas][${key2}].precio`}
                                                                autoFocus fullWidth
                                                                min="1"
                                                                type="tel"
                                                                margin="normal" label={'Precio €'}
                                                                onChange={(e) => {
                                                                    props.onChange(e.target.value.replace(/,/g, '.'))
                                                                }}
                                                                required/>
                                                            /* <input
                                                                 type="tel"
                                                                 {...props}
                                                                 onChange={(e) => {
                                                                     props.onChange(parseInt(e.target.value, 10));
                                                                 }}
                                                             />*/
                                                        )}
                                                        control={control}
                                                    />
                                                    <small style={{
                                                        position: 'absolute',
                                                        bottom: '-25px',
                                                        fontSize: '11px',
                                                        left: '0'
                                                    }}>Ejemplo: 5000.50</small>

                                                </div>
                                                <div className="Div-Boton-borrar"
                                                     style={{width: '80px', justifyContent: 'flex-start'}}>
                                                    {/* <Button variant="contained" style={{backgroundColor: 'red'}}
                                                            className="Boton-borrar"
                                                            onClick={() => handleRemoveClickLinea(key,key2)}> Eliminar</Button>*/}
                                                    <FontAwesomeIcon onClick={() => handleRemoveClickLinea(key, key2)}
                                                                     className="mb-1" style={{
                                                        cursor: 'pointer',
                                                        color: '#333',
                                                        fontSize: '20px',
                                                        marginLeft: '-10px'
                                                    }} icon={faTimes}/>

                                                </div>
                                            </div>)
                                    }
                                })
                            }
                            <div className="d-flex justify-content-between">
                                <Button type="button" variant="contained" className="Boton-borrar"
                                        style={{margin: '0 0 0 0!important'}}
                                        onClick={() => handleRemoveClickSection2(key)}>Eliminar este capítulo</Button>
                                <div>

                                    <Button type="button" variant="contained" className=" mr-3"
                                            style={{backgroundColor: '#303f9f', margin: '0 0 0 0!important'}}
                                            onClick={() => crearPartidaConTextoPorDefectoVacio(key)}>Añadir partida
                                        vacía</Button>

                                    <Button type="button" variant="contained" className=" mr-3"
                                            style={{backgroundColor: '#303f9f', margin: '0 0 0 0!important'}}
                                            onClick={() => crearPartidaConTextoPorDefecto(key)}>Añadir partida
                                        seleccionada a este capítulo</Button>
                                </div>

                            </div>
                            <br/>
                            <hr style={{backgroundColor: '#333', height: '3px', marginBottom: '6rem'}}/>

                            <br/>

                        </div>)
                })
            }
            <Button type="button" color="primary" variant="contained" className="Boton-crear"
                    onClick={crearNuevaSeccionNuevo}>Crear
                nuevo capítulo</Button>


        </>
    );
}

export default CrearFacturaStep2;