import React from 'react';
import moment from 'moment'

function Footer(props) {

    return (
        <footer className="footer">
            <div className="container">
                <div className="row row align-items-center flex-row-reverse">
                    <div className="col col-auto ml-auto">
                        {/*<div className="row row align-items-center">
                            <div className="col col-auto">
                                <ul className="list list-inline list-inline-dots mb-0">
                                    <li className="list-inline-item"><a href="./docs/index.html">Documentation</a>
                                    </li>
                                    <li className="list-inline-item"><a href="./faq.html">FAQ</a></li>
                                </ul>
                            </div>
                            <div className="col col-auto"><a className="btn btn-sm btn-outline-primary"
                                                             href="https://github.com/tabler/tabler-react">Source
                                code</a></div>
                        </div>*/}
                    </div>
                    <div className="col col-12 col-lg-auto mt-3 mt-lg-0 text-center">Copyright
                        © {moment().format('YYYY')} Grepol2. Todos los derechos reservados.
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;