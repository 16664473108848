import React, {useState} from 'react';
import {Page,  Grid} from "tabler-react";
import TablaFacturas from "./tabla-facturas";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import {Link as RouterLink} from "react-router-dom";
import {
    useParams
} from "react-router-dom";
function Facturas(props) {
    let { anyo } = useParams();
    const [fecha1,setFecha1] = useState("");
    const [fecha2,setFecha2] = useState("");
    return (
        <>

            <Page.Content title={"Listado facturas"}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit"  component={RouterLink} to="/">
                        Inicio
                    </Link>
                    <Link color="inherit"  component={RouterLink} to="/trabajos">
                        Facturas
                    </Link>


                    <Typography color="textPrimary">{anyo}</Typography>

                </Breadcrumbs>
                <Grid.Row>
                    <Grid.Col className="Bg-white">
                        {/*descargar csv*/}
                        <div className="d-flex mb-5 align-items-center">
                            <input type="date" id="date_start"  onChange={e => setFecha1(e.target.value)}/>
                            <input type="date" id="date_end"  onChange={e => setFecha2(e.target.value)} />
                            <a  target="_blank"
                                href={`http://api.facturacion.grepol2.es/api/facturas/descargar_csv/${fecha1}/${fecha2}/60dd284517517d875423ea4d417348a4`}

                                className="ml-2" style={{color:'black'}} >
                                <b> Descargar CSV</b>

                            </a>
                        </div>
                        {/*------*/}
                        <TablaFacturas anyo={anyo}/>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </>

    );
}

export default Facturas;