import React  from "react";
import "./style.scss";
import {Link} from "react-router-dom";

function CardMenu(props) {

    return (
        <Link to={props.link} className="col-12 col-sm-6 col-md-4  p-1 mb-1 mx-md-5 mx-0">
            <div className="CardMenu " >
                <div>
                    {props.children}

                </div>
                <span>
                    {props.title}
                </span>
                <div className="OnHoverText">
                   {props.description}
                </div>
            </div>



        </Link>
    );
}
CardMenu.defaultProps = {
    link: "/",
}
export default CardMenu;
