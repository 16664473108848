import React from 'react';
import MUIDataTable from "mui-datatables";
import './style.scss'

function Datatable(props) {


    let options_general = {
        print: false,
        textLabels: {
            pagination: {
                next: 'Siguiente',
                previous: 'Anterior',
                rowsPerPage: 'Filas por página',
                displayRows: 'de' // 1-10 of 30
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: 'Descargar ' + "CSV",
                viewColumns: 'Ver Columnas',
                filterTable: 'Filtrar'
            },
            body: {
                noMatch: 'No hay resultados'
            },
            viewColumns: {
                title: 'Columnas',
                titleAria: "Mostrar/Ocultar Columnas",
            },
            filter: {
                all: 'Todos',
                title: 'Filtros',
                reset: 'Reiniciar filtros'
            },

        }
    }

   let options = props.options
   let options_final=  {...options_general, ...options}


    return (
        <div className="px-4">
            <MUIDataTable
                title={props.titulo}
                data={props.data}
                columns={props.columns}
                options={options_final}
            />
        </div>
    );
}

export default Datatable;