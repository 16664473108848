import React, {useEffect} from 'react';

import Datatable from "../commons/datatables/datatable";
import ModalCrearDatatableRow from "../commons/modal-crear-datatable-row";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {useForm, Controller} from "react-hook-form";
import {CircularProgress, MenuItem} from "@material-ui/core";
import {useMutation, useQuery} from "react-query";
import routes from "../../services/api";
import {toast} from "react-toastify";
import {faPencilAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {startCloseLoading, startOpenLoading} from "../../redux/actions/ui";
import ModalEditarDatatableRow from "../commons/modal-editar-datatable-row";
import {setLocalStorageData} from "../../helpers/auth-helpers";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";
import Swal from "sweetalert2";




function TablaCapitulos(props) {
    let history = useHistory();
    const {register, handleSubmit, control} = useForm()
    const {
        register: register2,
        errors: errors2,
        handleSubmit: handleSubmit2,
        setValue: setValue2,
        getValues: getValues2,
        control:control2
    } = useForm();

    const dispatch = useDispatch();

    // Queries

    // Queries
    /* const query = useQuery('todos', getTodos)
 */
    const {status, data, error, isFetching, refetch } = useQuery("getAllCapitulos", async () => {
        const res = await routes.articulo_capitulo.getAll()
        return res.data;
    },{ refetchOnWindowFocus: false });

    // Mutations
    const mutation_create = useMutation((data) => routes.articulo_capitulo.create(data), {
        onSuccess: (response) => {
            toast.success("Capítulo creado correctamente", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })
            setOpenModal(false)
            refetch() // funcion para reload manual del useQuery

        },
        onError: (error) => {
            toast.error("Error al crear capítulo", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })
            setOpenModal(false)

        },
        onMutate: () => {

        }
    })
    const mutation_editar = useMutation((data) => routes.articulo_capitulo.editar(data), {
        onSuccess: (response) => {
            toast.success("Capítulo editado correctamente", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })
            setOpenModalEditar(false)
            refetch() // funcion para reload manual del useQuery

        },
        onError: (error) => {
            toast.error("Error al editar capitulo", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })
            setOpenModalEditar(false)

        },
        onMutate: () => {

        }
    })
    const mutation_get_capitulo = useMutation((id_capitulo) => routes.articulo_capitulo.get(id_capitulo), {
        onSuccess: (response,id_capitulo) => {
            setOpenModalEditar(true)

            rellenarModalEditar(id_capitulo,response.data)


        },
        onError: (error) => {
            toast.error("Error al editar capitulo", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })

        },
        onMutate: () => {

        }
    })
    const mutation_delete_capitulo = useMutation((id) => routes.articulo_capitulo.delete({id:id}), {
        onSuccess: (response) => {
            dispatch(startCloseLoading())

            toast.success("Articulo capitulo eliminada correctamente", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })

            let refetched = refetch()


        },
        onError: (error) => {
            dispatch(startCloseLoading())

            toast.error("Error al eliminar Articulo capitulo", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })


        }
    })

    useEffect(  mutation_get_capitulo.isLoading ? () => dispatch(startOpenLoading()) : () => dispatch(startCloseLoading()),[mutation_get_capitulo.isLoading])


    function rellenarModalEditar(id_capitulo,param_data){

        setValue2("id_capitulo_editar", id_capitulo)

        setValue2("idioma", param_data.idioma, {
            shouldValidate: true
        })
        setValue2("descripcion", param_data.descripcion, {
            shouldValidate: true
        })
        setValue2("orden", param_data.orden, {
            shouldValidate: true
        })
    }

    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: true,
                display: false,

            }
        },
        {
            name: "orden",
            label: "Orden",
            options: {
                filter: false,
                sort: true,
            }
        },

        {
            name: "descripcion",
            label: "Descripción",
            options: {
                filter: true,
                sort: false,

            }

        },


        {
            name: "idioma",
            label: "Idioma",

            options: {
                filter: true,
                sort: false,
                /*display: false,*/
                customBodyRender: (value, tableMeta, updateValue) => {

                    return (
                        <>
                         <span className="text-uppercase">{value}</span>
                        </>
                    );
                }

            }

        },


        {
            name: "ver",
            label: 'Acciones',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {

                    return (
                        <>
                        <button title={"Editar capitulo"} onClick={ () => {


                            mutation_get_capitulo.mutate(tableMeta.rowData[0]);



                        }} style={{color: '#333', border: 'none', background: 'transparent'}}

                                className="Btn-transparent-border-green Btn-small">

                            <FontAwesomeIcon style={{color: '#333', fontSize: '20px'}} icon={faPencilAlt}/>

                        </button>
                            <button title={"Ver partidas de este capitulo"} onClick={() => {

                                setLocalStorageData(tableMeta.rowData[2], 'capitulo_seleccionado_nombre')

                                history.push('/articulos-partidas/'+tableMeta.rowData[0] )

                            }} style={{color: '#333', border: 'none', background: 'transparent'}}

                                    className="Btn-transparent-border-green Btn-small">


                                <FontAwesomeIcon style={{color: '#333', fontSize: '20px'}} icon={faArrowRight}/>


                            </button>
                            <button title="Eliminar Articulo Capitulo" onClick={() => {

                                Swal.fire({
                                    title: 'Seguro que quieres eliminar el capitulo?',
                                    html: "<p class='my-3'>Se eliminara el capitulo: " + tableMeta.rowData[2] + "</p>",
                                    icon: 'question',
                                    width: 700,
                                    maxWidth: '90%',
                                    padding: '3em',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Eliminar'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        dispatch(startOpenLoading())

                                        mutation_delete_capitulo.mutate(tableMeta.rowData[0])
                                    }
                                })


                            }} style={{color: '#333', border: 'none', background: 'transparent'}}

                                    className="Btn-transparent-border-green Btn-small">

                                <FontAwesomeIcon style={{color: 'red', fontSize: '20px'}} icon={faTimes}/>

                            </button>
                            </>
                    );
                }
            },

        }
    ];

    const options = {
        selectableRows: 'none',


    }

    const onSubmit = data => {
        mutation_create.mutate(data)
    }
    const onSubmitEditar = data => {
        mutation_editar.mutate(data)
    }


    const [openModal, setOpenModal] = React.useState(false);
    const [openModalEditar, setOpenModalEditar] = React.useState(false);

function renderFormGenerico(submitFunction , register_param, control_param , setOpenModal_param,mutation_param, final_name_attr){

    return (
        <form onSubmit={submitFunction}>
            <input type="hidden" ref={register_param} name={`id_capitulo${final_name_attr}`} />
            <h5 className="mb-5">Datos para crear un capítulo por defecto y poderlo seleccionar al crear facturas o presupuestos.</h5>

            <div className="Grid mt-5 py-5" >
                <TextField inputRef={register_param}
                           autoFocus
                           margin="dense"
                           name="orden"
                           label="Orden"
                           type="number"
                           fullWidth
                />
                <TextField inputRef={register_param}
                           autoFocus
                           margin="dense"
                           multiline
                           name="descripcion"
                           label="Descripción"
                           type="text"
                           fullWidth
                />
                <FormControl fullWidth className="mt-2">
                    <InputLabel >
                        Idioma
                    </InputLabel>
                    <Controller
                        control={control_param}
                        name={`idioma`}
                        margin="dense"
                        defaultValue="es"
                        as={
                            <Select defaultValue="es">
                                <MenuItem value="es">
                                    Castellano
                                </MenuItem>
                                <MenuItem value="ca">
                                    Catalán
                                </MenuItem>

                            </Select>
                        }
                    />


                </FormControl>
            </div>




            <div className="d-flex " style={{marginTop: '50px'}}>
                <Button variant="contained" color="secondary" onClick={() => setOpenModal_param(false)} type="button"
                        className="mr-auto d-block my-5">
                    Cancelar
                </Button>
                {mutation_param.isLoading
                    ?
                    <button
                        className="ml-auto mr-0 MuiButtonBase-root MuiButton-root MuiButton-containedd-block my-5 MuiButton-containedPrimary"
                        tabIndex="0" type="button">
                        <CircularProgress style={{color: 'white',fontSize:'10px',width: '20px',height : '20px'}}/>

                    </button>
                    :
                    <Button variant="contained" color="primary" type="submit" className="ml-auto d-block my-5">
                        Guardar

                    </Button>
                }
            </div>

        </form>
    )
}
    function FormCrear() {
        return renderFormGenerico(handleSubmit(onSubmit),register,control,setOpenModal,mutation_create,'')

    }
    function FormEditar() {
        return renderFormGenerico(handleSubmit2(onSubmitEditar),register2,control2,setOpenModalEditar,mutation_editar,'_editar')
    }


    return (
        <div className="mw-100">


            <ModalCrearDatatableRow title={'Crear nuevo capitulo'} openModal={openModal} setOpenModal={setOpenModal}
                                    form={FormCrear()}/>
            <ModalEditarDatatableRow title={'Editar nuevo capitulo'} openModal={openModalEditar} setOpenModal={setOpenModalEditar}
                                    form={FormEditar()}/>
          {/*  <ModalCrearDatatableRow title={'Editar capitulo'} openModal={openModalEditar} setOpenModal={setOpenModalEditar}
            form={FormEditar()}/>*/}


            {data && !isFetching && !error && <Datatable titulo={''} data={data} options={options} columns={columns}/> }
            {
                !isFetching && !error && !data &&  <h3 className="text-center my-5 py-5 ">No hay datos</h3>
            }
            {
                isFetching &&
                <div className="d-flex justify-content-center align-items-center my-5 py-5"><CircularProgress/></div>

            }
            {
                error && <h3 className="text-center my-5 py-5 " style={{color:'red'}}>Ha ocurrido un error {error.message}</h3>
            }

        </div>
    );

}





export default TablaCapitulos;