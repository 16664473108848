import React from 'react';

import {Link} from "react-router-dom";
import Datatable from "../../commons/datatables/datatable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";

function TablaCuentasAnyo(props) {
    const columns = [
        {
            name: "anyo",
            label: "Año",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "cantidad_clientes",
            label: "Cantidad clientes",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "importe_total_presupuestos",
            label: "Importe total presupuestos",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "importe_total_certificaciones",
            label: "Importe total certificaciones",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "facturas",
            label: 'Ver clientes',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {

                    return (
                        <Link style={{color: '#333'}} to="/trabajos/clientes"
                           className="Btn-transparent-border-green Btn-small">

                            <FontAwesomeIcon icon={faEye}/>

                        </Link>
                    );
                }
            },

        }
    ];

    const data = [
        {
            anyo: "2020",
            cantidad_clientes: "54",
            importe_total_presupuestos: "154.554€",
            importe_total_certificaciones: "154.554€",
        },
        {
            anyo: "2021",
            cantidad_clientes: "64",
            importe_total_presupuestos: "254.554€",
            importe_total_certificaciones: "254.554€",
        },
        {
            anyo: "2022",
            cantidad_clientes: "57",
            importe_total_presupuestos: "134.554€",
            importe_total_certificaciones: "134.554€",
        },
    ];
    const options = {
        selectableRows: 'none',

    }
    return (
        <div className="mw-100 Tabla-centrada">
            <Datatable titulo={''} data={data} options={options} columns={columns}/>
        </div>
    );

}


export default TablaCuentasAnyo;