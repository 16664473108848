import React from 'react';
import {Page, Grid} from "tabler-react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import {Link as RouterLink} from "react-router-dom";
import './style.scss';
import ChartPie from "../commons/chart-pie";
import ChartBarra from "../commons/chart-barra";

function Dashboard(props) {

    return (
        <>

            <Page.Content title="Facturas Empresa1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" component={RouterLink} to="/inicio">
                        Inicio
                    </Link>


                </Breadcrumbs>
                <div className="d-flex css-grid-dashboard mb-5">
                    <div className="Bg-white col-12 col-md-6 ">
                        <p>
                            <strong className="my-5" style={{fontWeight: '700'}}> 28 Pendientes de cobro</strong>
                        </p>
                        <p>Importe total</p>
                        <p className="verde m-0">121,80€</p>

                        <div style={{width: '100%', height: '300px'}}>
                            <ChartPie data={
                                [
                                    {
                                        "id": "Dentro de plazo",
                                        "label": "Dentro de plazo",
                                        "value": 170.300,
                                        "color": "hsl(261, 70%, 50%)"
                                    },
                                    {
                                        "id": "Vencidas",
                                        "label": "Vencidas",
                                        "value": 30.000,
                                        "color": "hsl(167, 70%, 50%)"
                                    },

                                ]
                            }/>
                        </div>

                    </div>
                    <div className="Bg-white col-12 col-md-6 ">
                        <p>
                            <strong className="my-5" style={{fontWeight: '700'}}> 0 Pendientes de pago</strong>
                        </p>
                        <p>Importe total</p>
                        <p className="verde m-0">0,00€</p>

                        <div style={{width: '100%', height: '300px'}}>
                            <ChartPie data={
                                [
                                    {
                                        "id": "Dentro de plazo",
                                        "label": "Dentro de plazo",
                                        "value": 0,
                                        "color": "hsl(261, 70%, 50%)"
                                    },
                                    {
                                        "id": "Vencidas",
                                        "label": "Vencidas",
                                        "value": 0,
                                        "color": "hsl(167, 70%, 50%)"
                                    },

                                ]
                            }/>
                        </div>

                    </div>

                </div>

                <div className="d-flex css-grid-dashboard Bg-white flex-wrap">

                    <div className=" col-12 d-flex justify-content-start flex-column">
                        <p className="d-block">
                            <strong className="my-5" style={{fontWeight: '700'}}> Ingresos y gastos</strong>
                        </p>
                        <p className="verde m-0 d-block">Ingresos totales: 121,80€</p>
                        <p className="verde m-0 d-block" style={{color:'red!important'}}>Gastos totales: 121,80€</p>

                           {/* <div style={{width: '100%', height: '220px'}}>
                               <ChartPie data={
                                    [
                                        {
                                            "id": "Ingresos",
                                            "label": "Ingresos",
                                            "value": 170.300,
                                            "color": "hsl(261, 70%, 50%)"
                                        },
                                        {
                                            "id": "Gastos",
                                            "label": "Gastos",
                                            "value": 30.000,
                                            "color": "hsl(167, 70%, 50%)"
                                        },

                                    ]
                                }/>
                            </div>

*/}
                    </div>
                    <div className=" col-12 d-flex justify-content-start p-0">


                        <div style={{width: '100%', height: '400px'}}>
                            <ChartBarra data={
                                [
                                    {
                                        "country": "AD",
                                        "ingresos": 180.50,
                                        "ingresosColor": "hsl(51, 70%, 50%)",
                                        "gastos": 81,
                                        "gastosColor": "hsl(82, 70%, 50%)",

                                    },
                                    {
                                        "country": "AE",
                                        "ingresos": 12,
                                        "ingresosColor": "hsl(197, 70%, 50%)",
                                        "gastos": 3,
                                        "gastosColor": "hsl(359, 70%, 50%)",

                                    },
                                    {
                                        "country": "AF",
                                        "ingresos": 182,
                                        "ingresosColor": "hsl(199, 70%, 50%)",
                                        "gastos": 175,
                                        "gastosColor": "hsl(64, 70%, 50%)",

                                    },
                                    {
                                        "country": "AG",
                                        "ingresos": 17,
                                        "ingresosColor": "hsl(249, 70%, 50%)",
                                        "gastos": 189,
                                        "gastosColor": "hsl(57, 70%, 50%)",

                                    },
                                    {
                                        "country": "AI",
                                        "ingresos": 177,
                                        "ingresosColor": "hsl(211, 70%, 50%)",
                                        "gastos": 14,
                                        "gastosColor": "hsl(322, 70%, 50%)",

                                    },
                                    {
                                        "country": "AL",
                                        "ingresos": 136,
                                        "ingresosColor": "hsl(326, 70%, 50%)",
                                        "gastos": 38,
                                        "gastosColor": "hsl(20, 70%, 50%)",

                                    },
                                    {
                                        "country": "AM",
                                        "ingresos": 77,
                                        "ingresosColor": "hsl(244, 70%, 50%)",
                                        "gastos": 19,
                                        "gastosColor": "hsl(334, 70%, 50%)",

                                    }
                                ]
                            }/>
                        </div>


                    </div>
                </div>
            </Page.Content>
        </>

    )
        ;
}

export default Dashboard;