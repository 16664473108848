import React, {Component, useEffect, useState} from 'react';
import { EditorState, ContentState,convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html'
import htmlToDraft from 'html-to-draftjs'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./styles.scss"
function Wysiwyg(props) {

    const [wysiwygValue,setWysiwygValue] = useState(EditorState.createWithContent(stateFromHTML(props.valueInicial)))
        useEffect(()=>{
            console.log(props.valueInicial)
           /* setWysiwygValue(props.valueInicial);
*/
        },[])
    const onEditorStateChange = editorState => {
        setWysiwygValue(editorState);

        return props.onChange(
            draftToHtml(convertToRaw(editorState.getCurrentContent()))
        );
    };

    return (
        <div>
            <p style={{top:'-30px', position:'absolute'}}>Descripción:</p>
            <Editor

                toolbarOnFocus
                editorState={wysiwygValue}
                wrapperClassName="border px-2"
                toolbar={{
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },

                }}
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
            />
           {/* <textarea
                disabled
                value={draftToHtml(convertToRaw(wysiwygValue.getCurrentContent()))}
            />*/}
        </div>
    );
}

export default Wysiwyg;