import React from 'react';


function NavbarMenu(props) {

    return (
        <div className={`d-lg-flex p-0 `} style={{background:'white', borderBottom: '1px solid rgba(0, 40, 100, 0.12)'}}>
            <div className="container">
                <div className="row row align-items-center">
                    <div className="col-lg-3 ml-auto"></div>
                    <div className="col col-lg order-lg-first">
                        <ul className="nav nav-tabs border-0 flex-column flex-lg-row">
                            {props.menu}


                        </ul>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default NavbarMenu;