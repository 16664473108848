import React, {useEffect} from 'react';

import TextField from "@material-ui/core/TextField";
import '../../../../assets/forms-pagina.scss'
import {Controller, useFormContext} from "react-hook-form";

function CrearFacturaStep4(props) {
    const methods = useFormContext();
    const { reset, register,control } = methods;
    useEffect(() => {
        reset({ ...props.dataForm}, { errors: true });
    }, []);
    return (
        <>

            <>

                <h5 className="mb-3">Descuentos</h5>
                <div className="Row-flex Columns3 mt-0">
                    <div>

                        <TextField inputRef={register}  name="descripcion_descuento" autoFocus fullWidth
                                   margin="normal" label={'Descripción del descuento'}
                        />
                    </div>
                    <div className="position-relative">

                        <Controller
                            name="importe_descuento"
                            render={(props) => (
                                <TextField
                                    {...props}
                                    type="tel"
                                    autoFocus fullWidth
                                    margin="normal" label={'Importe del descuento en €'}
                                    onChange={(e) => {
                                        props.onChange(e.target.value.replace(/,/g, '.'))
                                    }}
                                    required/>
                                /* <input
                                     type="tel"
                                     {...props}
                                     onChange={(e) => {
                                         props.onChange(parseInt(e.target.value, 10));
                                     }}
                                 />*/
                            )}
                            control={control}
                        />
                        <small style = {{ position: 'absolute', bottom:'-25px', fontSize:'11px', left:'0' }}>Ejemplo: 5000.50</small>

                    </div>

                    <div>


                    </div>
                </div>
                <div className="Row-flex Columns3 mt-0">
                    <div>

                        <TextField inputRef={register}  name="descripcion_descuento2" autoFocus fullWidth
                                   margin="normal" label={'Descripción del descuento 2'}
                        />
                    </div>
                    <div className="position-relative">

                        <Controller
                            name="importe_descuento2"
                            render={(props) => (
                                <TextField
                                    {...props}
                                    type="tel"
                                    autoFocus fullWidth
                                    margin="normal" label={'Importe del descuento 2 en €'}
                                    onChange={(e) => {
                                        props.onChange(e.target.value.replace(/,/g, '.'))
                                    }}
                                    required/>
                                /* <input
                                     type="tel"
                                     {...props}
                                     onChange={(e) => {
                                         props.onChange(parseInt(e.target.value, 10));
                                     }}
                                 />*/
                            )}
                            control={control}
                        />
                        <small style = {{ position: 'absolute', bottom:'-25px', fontSize:'11px', left:'0' }}>Ejemplo: 5000.50</small>

                    </div>

                    <div>


                    </div>
                </div>
                <div className="Row-flex Columns3 mt-0">
                    <div>

                        <TextField inputRef={register}  name="descripcion_descuento3" autoFocus fullWidth
                                   margin="normal" label={'Descripción del descuento 3'}
                        />
                    </div>
                    <div className="position-relative">

                        <Controller
                            name="importe_descuento3"
                            render={(props) => (
                                <TextField
                                    {...props}
                                    type="tel"
                                    autoFocus fullWidth
                                    margin="normal" label={'Importe del descuento 3 en €'}
                                    onChange={(e) => {
                                        props.onChange(e.target.value.replace(/,/g, '.'))
                                    }}
                                    required/>
                                /* <input
                                     type="tel"
                                     {...props}
                                     onChange={(e) => {
                                         props.onChange(parseInt(e.target.value, 10));
                                     }}
                                 />*/
                            )}
                            control={control}
                        />
                        <small style = {{ position: 'absolute', bottom:'-25px', fontSize:'11px', left:'0' }}>Ejemplo: 5000.50</small>

                    </div>

                    <div>


                    </div>
                </div>

                <div className="Row-flex Columns3 mt-0">
                    <div>

                        <TextField inputRef={register}  name="descripcion_descuento4" autoFocus fullWidth
                                   margin="normal" label={'Descripción del descuento 4'}
                        />
                    </div>
                    <div className="position-relative">

                        <Controller
                            name="importe_descuento4"
                            render={(props) => (
                                <TextField
                                    {...props}
                                    type="tel"
                                    autoFocus fullWidth
                                    margin="normal" label={'Importe del descuento 4 en €'}
                                    onChange={(e) => {
                                        props.onChange(e.target.value.replace(/,/g, '.'))
                                    }}
                                    required/>
                                /* <input
                                     type="tel"
                                     {...props}
                                     onChange={(e) => {
                                         props.onChange(parseInt(e.target.value, 10));
                                     }}
                                 />*/
                            )}
                            control={control}
                        />
                        <small style = {{ position: 'absolute', bottom:'-25px', fontSize:'11px', left:'0' }}>Ejemplo: 5000.50</small>

                    </div>

                    <div>


                    </div>
                </div>

                <div className="Row-flex Columns3 mt-0">
                    <div>

                        <TextField inputRef={register}  name="descripcion_descuento5" autoFocus fullWidth
                                   margin="normal" label={'Descripción del descuento 5'}
                        />
                    </div>
                    <div className="position-relative">

                        <Controller
                            name="importe_descuento5"
                            render={(props) => (
                                <TextField
                                    {...props}
                                    type="tel"
                                    autoFocus fullWidth
                                    margin="normal" label={'Importe del descuento 5 en €'}
                                    onChange={(e) => {
                                        props.onChange(e.target.value.replace(/,/g, '.'))
                                    }}
                                    required/>
                                /* <input
                                     type="tel"
                                     {...props}
                                     onChange={(e) => {
                                         props.onChange(parseInt(e.target.value, 10));
                                     }}
                                 />*/
                            )}
                            control={control}
                        />
                        <small style = {{ position: 'absolute', bottom:'-25px', fontSize:'11px', left:'0' }}>Ejemplo: 5000.50</small>

                    </div>

                    <div>


                    </div>
                </div>

                <h5 className="mb-3">Cargos extra</h5>
                <div className="Row-flex Columns3 mt-0">
                    <div>

                        <TextField inputRef={register}  name="descripcion_cargo_extra" autoFocus fullWidth
                                   margin="normal" label={'Descripción del cargo extra'}
                        />
                    </div>
                    <div className="position-relative">


                        <Controller
                            name="importe_cargo_extra"
                            render={(props) => (
                                <TextField
                                    {...props}
                                    type="tel"
                                    autoFocus fullWidth
                                    margin="normal" label={'Importe del cargo extra en €'}
                                    onChange={(e) => {
                                        props.onChange(e.target.value.replace(/,/g, '.'))
                                    }}
                                    required/>
                                /* <input
                                     type="tel"
                                     {...props}
                                     onChange={(e) => {
                                         props.onChange(parseInt(e.target.value, 10));
                                     }}
                                 />*/
                            )}
                            control={control}
                        />
                        <small style = {{ position: 'absolute', bottom:'-25px', fontSize:'11px', left:'0' }}>Ejemplo: 5000.50</small>

                    </div>
                    <div>


                    </div>
                </div>
                <div className="Row-flex Columns3 mt-0">
                    <div>

                        <TextField inputRef={register}  name="descripcion_cargo_extra2" autoFocus fullWidth
                                   margin="normal" label={'Descripción del cargo extra 2'}
                        />
                    </div>
                    <div className="position-relative">


                        <Controller
                            name="importe_cargo_extra2"
                            render={(props) => (
                                <TextField
                                    {...props}
                                    type="tel"
                                    autoFocus fullWidth
                                    margin="normal" label={'Importe del cargo extra 2 en €'}
                                    onChange={(e) => {
                                        props.onChange(e.target.value.replace(/,/g, '.'))
                                    }}
                                    required/>
                                /* <input
                                     type="tel"
                                     {...props}
                                     onChange={(e) => {
                                         props.onChange(parseInt(e.target.value, 10));
                                     }}
                                 />*/
                            )}
                            control={control}
                        />
                        <small style = {{ position: 'absolute', bottom:'-25px', fontSize:'11px', left:'0' }}>Ejemplo: 5000.50</small>

                    </div>
                    <div>


                    </div>
                </div>
                <div className="Row-flex Columns3 mt-0">
                    <div>

                        <TextField inputRef={register}  name="descripcion_cargo_extra3" autoFocus fullWidth
                                   margin="normal" label={'Descripción del cargo extra 3'}
                        />
                    </div>
                    <div className="position-relative">


                        <Controller
                            name="importe_cargo_extra3"
                            render={(props) => (
                                <TextField
                                    {...props}
                                    type="tel"
                                    autoFocus fullWidth
                                    margin="normal" label={'Importe del cargo extra 3 en €'}
                                    onChange={(e) => {
                                        props.onChange(e.target.value.replace(/,/g, '.'))
                                    }}
                                    required/>
                                /* <input
                                     type="tel"
                                     {...props}
                                     onChange={(e) => {
                                         props.onChange(parseInt(e.target.value, 10));
                                     }}
                                 />*/
                            )}
                            control={control}
                        />
                        <small style = {{ position: 'absolute', bottom:'-25px', fontSize:'11px', left:'0' }}>Ejemplo: 5000.50</small>

                    </div>
                    <div>


                    </div>
                </div>
                <div className="Row-flex Columns3 mt-0">
                    <div>

                        <TextField inputRef={register}  name="descripcion_cargo_extra4" autoFocus fullWidth
                                   margin="normal" label={'Descripción del cargo extra 4'}
                        />
                    </div>
                    <div className="position-relative">


                        <Controller
                            name="importe_cargo_extra4"
                            render={(props) => (
                                <TextField
                                    {...props}
                                    type="tel"
                                    autoFocus fullWidth
                                    margin="normal" label={'Importe del cargo extra 4 en €'}
                                    onChange={(e) => {
                                        props.onChange(e.target.value.replace(/,/g, '.'))
                                    }}
                                    required/>
                                /* <input
                                     type="tel"
                                     {...props}
                                     onChange={(e) => {
                                         props.onChange(parseInt(e.target.value, 10));
                                     }}
                                 />*/
                            )}
                            control={control}
                        />
                        <small style = {{ position: 'absolute', bottom:'-25px', fontSize:'11px', left:'0' }}>Ejemplo: 5000.50</small>

                    </div>
                    <div>


                    </div>
                </div>
                <div className="Row-flex Columns3 mt-0">
                    <div>

                        <TextField inputRef={register}  name="descripcion_cargo_extra5" autoFocus fullWidth
                                   margin="normal" label={'Descripción del cargo extra 5'}
                        />
                    </div>
                    <div className="position-relative">


                        <Controller
                            name="importe_cargo_extra5"
                            render={(props) => (
                                <TextField
                                    {...props}
                                    type="tel"
                                    autoFocus fullWidth
                                    margin="normal" label={'Importe del cargo extra 5 en €'}
                                    onChange={(e) => {
                                        props.onChange(e.target.value.replace(/,/g, '.'))
                                    }}
                                    required/>
                                /* <input
                                     type="tel"
                                     {...props}
                                     onChange={(e) => {
                                         props.onChange(parseInt(e.target.value, 10));
                                     }}
                                 />*/
                            )}
                            control={control}
                        />
                        <small style = {{ position: 'absolute', bottom:'-25px', fontSize:'11px', left:'0' }}>Ejemplo: 5000.50</small>

                    </div>
                    <div>


                    </div>
                </div>
                {/*variant="outlined"
*/}


                <div className="d-flex justify-content-between">
                    {/* <Button variant="contained" color="secondary" type="submit">
                    <Link to="/home"><span >Cancelar
  </span></Link>
                    </Button>

                    <Button variant="contained" color="primary" type="submit">
                        <span>
                                {cargando ? <CircularProgress style={{'color': '#fff'}}/> : 'Guardar'}
                                </span>
                    </Button>*/}

                </div>
            </>

        </>
    );
}

export default CrearFacturaStep4;