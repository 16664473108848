import React, {useEffect, useState} from 'react';

import moment from 'moment';
import InputLabel from "@material-ui/core/InputLabel";
import {Controller, useForm, useFormContext} from "react-hook-form";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import '../../../../assets/forms-pagina.scss'
import {CircularProgress, Fab, MenuItem, Tooltip} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import {useMutation, useQuery} from "react-query";
import routes from "../../../../services/api";
import {startCloseLoading, startOpenLoading} from "../../../../redux/actions/ui";
import {toast} from "react-toastify";

function EditarPresupuestoStep3(props) {
    const methods = useFormContext();
    const { reset, register,control , setValue} = methods;
    useEffect(() => {
        console.log(props.dataForm)
        reset({ ...props.dataForm}, { errors: true });
    }, []);


    const [metodoPago, setMetodoPago] = useState(props.dataForm.metodo_pago ||'transferencia_bancaria')
    const [cuenta, setCuenta] = useState(props.dataForm.cuenta ||'')
    const {status, data = [], error, isFetching, refetch} = useQuery("getAllCuentaBancaria", async () => {
        const res = await routes.cuenta_bancaria.getAll()
        return res.data;
    }, {refetchOnWindowFocus: false});
    const changeHandlerMetodoPago = data => {
        setValue('metodo_pago',data.target.value)
        setValue('cuenta','')
        setCuenta('')
        setMetodoPago(data.target.value)
    }
    const changeHandlerCuenta = data => {
        setValue('cuenta',data.target.value)

        setCuenta(data.target.value)
    }


    return (
        <>


            <div className="d-flex py-5">
                {/*  <h4 className="Titulo-seccion-form ">
                        Cliente
                    </h4>*/}
                <FormControl fullWidth className="mr-3">
                    <InputLabel>
                        Método de pago
                    </InputLabel>

                    <Controller
                        control={control}
                        name="metodo_pago"
                        defaultValue="transferencia_bancaria"
                        render={() => (

                            <Select onChange={changeHandlerMetodoPago}  defaultValue={props.dataForm.metodo_pago ||'transferencia_bancaria'}>
                                <MenuItem value="efectivo">En efectivo</MenuItem>
                                <MenuItem value="cargar_cuenta">Cargar en cuenta</MenuItem>
                                <MenuItem value="transferencia_bancaria">Transferencia bancaria</MenuItem>
                                <MenuItem value="tarjeta">Pago mediante tarjeta</MenuItem>
                                <MenuItem value="contarto_adjudicacion">Contrato adjudicación</MenuItem>
                                <MenuItem value="pagare_no_orden">Pagaré no a la orden</MenuItem>
                                <MenuItem value="cheque">Cheque</MenuItem>
                                <MenuItem value="cheque_bancario">Cheque bancario</MenuItem>
                                <MenuItem value="reposicion">Reposición</MenuItem>
                                <MenuItem value="giro_postal">Giro postal</MenuItem>
                                <MenuItem value="otros">Otros</MenuItem>

                            </Select>
                            )}

                    />
                </FormControl>
                {
                    (metodoPago === 'transferencia_bancaria' || metodoPago === 'cargar_cuenta' || metodoPago === 'contarto_adjudicacion' || metodoPago === 'giro_postal') &&
                    (
                        <FormControl fullWidth className="position-relative ml-3" >
                            <InputLabel>
                                Recibir pago en esta cuenta:
                            </InputLabel>

                            <Controller
                                control={control}
                                name="cuenta"
                                defaultValue=""
                                render={() => (
                                    <Select onChange={changeHandlerCuenta} defaultValue={props.dataForm.cuenta ||''}>
                                        <MenuItem value="">Seleccionar</MenuItem>
                                        {data.map((item, i) => <MenuItem key={i}
                                                                         value={item.id}>{item.nombre}</MenuItem>)}

                                    </Select>
                                )}
                            />


                            <ModalCrearCuenta cuenta={cuenta} refetch_cuenta_bancaria ={refetch} />

                        </FormControl>
                    )
                }




            </div>
            <div className="d-flex pyy" >
                <FormControl fullWidth className="mr-3">
                    <InputLabel>
                        Vencimiento
                    </InputLabel>
                    <Controller
                        control={control}
                        name="vencimiento"
                        defaultValue=""
                        as={
                            <Select defaultValue="">
                                <MenuItem value="">Seleccionar</MenuItem>
                                <MenuItem value="al_contado">Al contado</MenuItem>
                                <MenuItem value="15">15 días</MenuItem>
                                <MenuItem value="30">30 días</MenuItem>
                                <MenuItem value="60">60 días</MenuItem>
                                <MenuItem value="90">90 días</MenuItem>
                                <MenuItem value="120">120 días</MenuItem>

                            </Select>
                        }
                    />
                </FormControl>
                <FormControl fullWidth className="ml-3 labelup">
                    <TextField inputRef={register} name="vencimiento_manual" autoFocus fullWidth InputLabelProps={{
                        shrink: true,
                    }}
                               type="date"
                               defaultValue={props.dataForm.fecha_vencimiento || ''}
                               margin="normal" label={'Fecha de vencimiento prioritaria:'}
                    />
                </FormControl>


            </div>


            {/*variant="outlined"
*/}


            <div className="d-flex justify-content-between">
                {/* <Button variant="contained" color="secondary" type="submit">
                    <Link to="/home"><span >Cancelar
  </span></Link>
                    </Button>

                    <Button variant="contained" color="primary" type="submit">
                        <span>
                                {cargando ? <CircularProgress style={{'color': '#fff'}}/> : 'Guardar'}
                                </span>
                    </Button>*/}

            </div>

        </>
    );


}

function ModalCrearCuenta(props) {
    const [open, setOpen] = React.useState(false);
    const {register, handleSubmit, control, getValues, setValue} = useForm()
    const [tipoModal, setTipoModal] = useState('crear')

    const [tipoCuenta, setTipoCuenta] = useState('iban')
    const mutation_create_cuenta_bancaria = useMutation((data) => routes.cuenta_bancaria.create(data), {
        onSuccess: (response) => {
            props.refetch_cuenta_bancaria()

            toast.success("Cuenta bancaria creada correctamente", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })
            setOpen(false)


        },
        onError: (error) => {

            props.refetch_cuenta_bancaria()

            toast.error("Error al crear Cuenta bancaria", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })
            setOpen(false)



        },
        onMutate: () => {

        }
    })
    const mutation_editar_cuenta_bancaria = useMutation((data) => routes.cuenta_bancaria.editar(data), {
        onSuccess: (response) => {
            props.refetch_cuenta_bancaria()


            toast.success("Cuenta bancaria editada correctamente", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })
            setOpen(false)



        },
        onError: (error) => {
            props.refetch_cuenta_bancaria()


            toast.error("Error al editar cuenta bancaria", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })
            setOpen(false)



        },
        onMutate: () => {

        }
    })
    const handleClickOpen = (tipo_modal) => {
        setTipoModal(tipo_modal)
        setOpen(true);
    };


    const mutation_get_cuenta_bancaria = useMutation((cuenta) => routes.cuenta_bancaria.get(cuenta), {
        onSuccess: (response) => {

            setValue("nombre", response.data.nombre, {
                shouldValidate: true
            })
            setValue("tipo_dato_bancario", response.data.tipo_dato_bancario, {
                shouldValidate: true
            })
            setValue("numero_cuenta", response.data.numero_cuenta, {
                shouldValidate: true
            })
            setValue("bic", response.data.bic, {
                shouldValidate: true
            })
        },
        onError: (error) => {

            toast.error("Error al recuperar datos de la cuenta", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })

        },
        onMutate: () => {

        }
    })


    const getCuenta = () =>{
        if(props.cuenta!='') {
            mutation_get_cuenta_bancaria.mutate(props.cuenta)


        }
    }

    const handleClose = () => {
        setOpen(false);
    };


    function crear_cuenta_submit() {
        if (tipoModal === 'crear') {
            let datos_crear_cuenta = getValues()
            datos_crear_cuenta.tipo_dato_bancario = tipoCuenta

            mutation_create_cuenta_bancaria.mutate(getValues())

        } else if (tipoModal === 'editar') {
            let datos_editar_cuenta = getValues()
            datos_editar_cuenta.id_cuenta = props.cuenta
            datos_editar_cuenta.tipo_dato_bancario = tipoCuenta

            mutation_editar_cuenta_bancaria.mutate(datos_editar_cuenta)

        }


    }

    const changeHandlerTipoCuenta = data => {
        setTipoCuenta(data.target.value)
    }
    return (
        <>

            {
                props.cuenta !== '' &&
                (
                    <div className="position-absolute " onClick={() => {
                        getCuenta()
                        handleClickOpen('editar')
                    }}
                         style={{right: '-25px', top: '25px', cursor: 'pointer'}}>
                        <FontAwesomeIcon style={{color: '#333', fontSize: '15px'}} icon={faPencilAlt}/>
                    </div>
                )

            }

            <div className="position-absolute " onClick={() => handleClickOpen('crear')}
                 style={{bottom: '-35px', cursor: 'pointer'}}>
                <FontAwesomeIcon style={{color: '#333', fontSize: '15px'}} icon={faPlus}/>
                <span className="d-inline-block ml-2">
                                    Nueva cuenta bancaria para recibir pagos
                                </span>
            </div>
            <Dialog open={open} onClose={handleClose} className="Modal-crear Cuenta-bancaria"
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Nueva cuenta bancaria para recibir pagos </DialogTitle>
                <DialogContent>
                    {/*   <DialogContentText>
                        {props.subtitle}
                    </DialogContentText>*/}
                    <h4 className="mt-1">Crear nueva cuenta bancaria</h4>
                    <h5 className="mb-5">Datos necesarios para crear la cuenta.</h5>
                    <form>
                        <div className="Grid mt-5">

                            <TextField inputRef={register}
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       name="nombre"
                                       label="Nombre de la cuenta"
                                       type="text"

                                       fullWidth
                            />

                            <FormControl fullWidth>
                                <InputLabel>
                                    Tipo
                                </InputLabel>

                                <Select name="tipo_dato_bancario" value={tipoCuenta} onChange={changeHandlerTipoCuenta}>
                                    <MenuItem value="iban" >IBAN</MenuItem>
                                    <MenuItem value="cuenta_bancaria">Nº Cuenta</MenuItem>

                                </Select>

                            </FormControl>
                            {
                                tipoCuenta === 'iban' && (
                                    <TextField inputRef={register}

                                               InputLabelProps={{
                                                   shrink: true,
                                               }}
                                               name="numero_cuenta"
                                               label="IBAN"
                                               type="text"

                                               fullWidth
                                    />
                                )
                            }
                            {
                                tipoCuenta === 'cuenta_bancaria' && (
                                    <TextField inputRef={register}

                                               InputLabelProps={{
                                                   shrink: true,
                                               }}
                                               name="numero_cuenta"
                                               label="Cuenta bancaria"
                                               type="text"
                                               fullWidth
                                    />
                                )
                            }

                            <TextField inputRef={register}


                                       name="bic"
                                       label="BIC"
                                       type="text"
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       fullWidth
                            />
                        </div>


                        <div className="d-flex my-5">

                            <Button variant="contained" color="secondary" onClick={() => setOpen(false)} type="button"
                                    className="mr-auto d-block my-5">
                                Cancelar
                            </Button>
                            {mutation_create_cuenta_bancaria.isLoading || mutation_editar_cuenta_bancaria.isLoading
                                ?
                                <button
                                    className="ml-auto mr-0 MuiButtonBase-root MuiButton-root MuiButton-containedd-block my-5 MuiButton-containedPrimary"
                                    tabIndex="0" type="button">
                                    <CircularProgress
                                        style={{color: 'white', fontSize: '10px', width: '20px', height: '20px'}}/>

                                </button>
                                :
                                <Button variant="contained" color="primary" type="button"
                                        onClick={() => crear_cuenta_submit()}
                                        className="ml-auto d-block my-5">
                                    Guardar

                                </Button>
                            }
                        </div>
                    </form>

                    {/*    <div className="d-flex mt-5">
                            <Button variant="contained" color="secondary" type="button" className="mr-auto d-block my-5"
                                    onClick={() => setOpen(false)}>
                                Cancelar
                            </Button>
                            <Button variant="contained" color="primary" type="button" onClick={crearCuentaSubmit} className="ml-auto d-block my-5">
                                Guardar
                            </Button>
                        </div>*/}


                </DialogContent>

            </Dialog>
        </>
    );
}

export default EditarPresupuestoStep3;