import React from 'react';

import {Link} from "react-router-dom";
import Datatable from "../../commons/datatables/datatable";
import ModalCrearDatatableRow from "../../commons/modal-crear-datatable-row";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";
import {faEdit} from "@fortawesome/free-solid-svg-icons/faEdit";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons/faFilePdf";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons/faFileExcel";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {faCross} from "@fortawesome/free-solid-svg-icons/faCross";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Controller, useForm} from "react-hook-form";
import Select from "@material-ui/core/Select";
import {MenuItem} from "@material-ui/core";

function TablaDocumentosObra(props) {
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "tipo_documento",
            label: "Tipo ",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "nombre_documento",
            label: "Nombre ",
            options: {
                filter: false,
                sort: true,

            }

        },
        {
            name: "extension_documento",
            label: "Extensión ",
            options: {
                filter: true,
                sort: true,

            }

        },
        {
            name: "ver",
            label: 'Acciones',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {

                    return (
                        <>
                            {tableMeta.rowData[1] == 'Presupuesto' ? <><a target="_blank"
                                                                          href="https:/sergiocc.es/pdf-test"><FontAwesomeIcon
                                    icon={faFilePdf}
                                    title="Exportar PDF"
                                    className="mr-3"/></a><FontAwesomeIcon
                                    icon={faFileExcel} title="Exportar Excel" className="mr-3"/><FontAwesomeIcon
                                    icon={faEdit} title="Editar" className="mr-3"/></> :
                                <FontAwesomeIcon icon={faEye} title="Ver" className="mr-3"/>}
                            <FontAwesomeIcon icon={faTimes} style={{color: 'red'}} title="Eliminar"/>


                        </>
                    );
                }
            },

        }
    ];

    const data = [
        {
            id: 1,
            tipo_documento: "Presupuesto",
            nombre_documento: "Presupuesto Obra Colegio San Ramon 1",
            extension_documento: ""

        }
        ,

        {
            id: 2,
            tipo_documento: "Imagen",
            nombre_documento: "Foto 1 Colegio San Ramon",
            extension_documento: "jpg"

        },

        {
            id: 5,
            tipo_documento: "Word",
            nombre_documento: "Documentación Planta 1 Colegio San ramon",
            extension_documento: "doc"

        }

    ];
    const options =
        {
            selectableRows: 'none',


        }


    const {register, handleSubmit, control} = useForm()
    const [openModal, setOpenModal] = React.useState(false);
    const onSubmit = data => {
        console.log(data)

    }
    function FormCrear() {
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <h4 className="mt-1">Datos fiscales</h4>
                <h5>Datos necesarios del cliente o proveedor para la facturación.</h5>
                <div className="Grid">
                    <TextField inputRef={register}
                               autoFocus
                               margin="dense"
                               name="nif"
                               label="NIF"
                               type="text"
                               fullWidth
                    />
                    <TextField inputRef={register}
                               autoFocus
                               margin="dense"
                               name="razon_social"
                               label="Nombre o razón social"
                               type="text"
                               fullWidth
                    />

                    <TextField inputRef={register}
                               autoFocus
                               margin="dense"
                               name="country"
                               label="País"
                               type="text"
                               fullWidth
                    />
                    <TextField inputRef={register}
                               autoFocus
                               margin="dense"
                               name="city"
                               label="Ciudad"
                               type="text"
                               fullWidth
                    />
                    <TextField inputRef={register}
                               autoFocus
                               margin="dense"
                               name="direction"
                               label="Dirección"
                               type="text"
                               fullWidth
                    />

                </div>
                <h4>Datos de contacto</h4>
                <h5>Datos de la persona de contacto en la empresa.</h5>

                <div className="Grid col3">
                    <TextField inputRef={register}
                               autoFocus
                               margin="dense"
                               name="name"
                               label="Nombre y Apellidos"
                               type="text"
                               fullWidth
                    />
                    <TextField inputRef={register}
                               autoFocus
                               margin="dense"
                               name="email"
                               label="Email"
                               type="email"
                               fullWidth
                    />
                    <TextField inputRef={register}
                               autoFocus
                               margin="dense"
                               name="phone"
                               label="Teléfono"
                               type="text"
                               fullWidth
                    />

                </div>
                <h4>Métodos de entrega y pago</h4>
                <h5 style={{marginBottom: '40px'}}>Método de entrega de los documentos electrónicos y de pago
                    predeterminados para este cliente </h5>
                <div className="Grid col3">

                    <FormControl fullWidth>
                        <InputLabel>
                            Métodos de entrega por defecto
                        </InputLabel>
                        <Controller
                            control={control}
                            name="metodos_entrega_defecto"
                            defaultValue="adjunto_email"
                            as={
                                <Select defaultValue="adjunto_email">
                                    <MenuItem value="adjunto_email">
                                        Adjunto por email
                                    </MenuItem>


                                </Select>
                            }
                        />


                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>
                            Métodos de pago por defecto
                        </InputLabel>
                        <Controller
                            control={control}
                            name="metodos_pago_defecto"
                            defaultValue="transferencia_bancaria"
                            as={
                                <Select defaultValue="transferencia_bancaria">
                                    <MenuItem value="efectivo">En efectivo</MenuItem>
                                    <MenuItem value="cargar_cuenta">Cargar en cuenta</MenuItem>
                                    <MenuItem value="transferencia_bancaria">Transferencia bancaria</MenuItem>
                                    <MenuItem value="tarjeta">Pago mediante tarjeta</MenuItem>
                                </Select>
                            }
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel>
                            Tipo IVA
                        </InputLabel>
                        <Controller
                            control={control}
                            name="tipo_iva"
                            defaultValue="21"
                            as={
                                <Select defaultValue="21">
                                    <MenuItem value="exento">Sujeto Pasivo</MenuItem>
                                    <MenuItem value="0">0.0% Tasa cero</MenuItem>
                                    <MenuItem value="4">4.0% Tasa super reducida</MenuItem>
                                    <MenuItem value="10">10.0% Tasa baja</MenuItem>
                                    <MenuItem selected="selected" value="21">21.0% Estándar</MenuItem>
                                </Select>
                            }
                        />
                    </FormControl>
                </div>

                <div className="d-flex " style={{marginTop: '50px'}}>
                    <Button variant="contained" color="secondary" onClick={() => setOpenModal(false)} type="button"
                            className="mr-auto d-block my-5">
                        Cancelar
                    </Button>
                    <Button variant="contained" color="primary" type="submit" className="ml-auto d-block my-5">
                        Guardar
                    </Button>
                </div>

            </form>
        )
    }


    return (
        <div className="mw-100">


            <ModalCrearDatatableRow title={'Crear nueva obra de Nombre Cliente'} openModal={openModal} setOpenModal={setOpenModal}
                                    form={FormCrear()}/>


            <Datatable titulo={''} data={data} options={options} columns={columns}/>
        </div>
    );
}


export default TablaDocumentosObra;