import React from 'react';
import {Page,  Grid} from "tabler-react";
import TablaClientes from "./tabla-clientes";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import {Link as RouterLink} from "react-router-dom";
import {
    useParams
} from "react-router-dom";
function Clientes(props) {
    let { anyo } = useParams();

    let string_titul = 'Clientes';

    if(anyo) string_titul = "Clientes año "+anyo

    return (
        <>
            <Page.Content title={string_titul}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit"  component={RouterLink} to="/">
                        Inicio
                    </Link>
                    <Link color="inherit"  component={RouterLink} to="/trabajos">
                        Trabajos por año
                    </Link>


                    <Typography color="textPrimary">{anyo}</Typography>

                </Breadcrumbs>
                <Grid.Row>
                    <Grid.Col className="Bg-white">

                        <TablaClientes anyo={anyo}/>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </>

    );
}

export default Clientes;