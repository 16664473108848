import React, {useContext} from "react";
import {AuthDataContext} from "../context/auth-context";
import {getLocalStorageData} from "../helpers/auth-helpers";
import {Redirect, Route} from "react-router-dom";

/* const PrivateRoute = ({component, ...options}) => {
    const {state} = useContext(AuthDataContext);

    if (getLocalStorageData('token') || state.token) {
        return <Route {...options} component={component}/>

    } else {
        return <Redirect to='/login'/>;

    }
};
 */
export const PrivateRoute = ({
                                 isAuthenticated,
                                 component: Component,
                                 ...rest
                             }) => {

    /*PARA QUE NO ME REDIRIJA AL LOGIN AL HACER RELOAD HASTA HACER EL CHECKAUTH
    * SI YA ESTOY LOGUEADO Y HAY KEY EN EL LOCALESTORAGE DEJO QUE HAGA EL CHECK AQUI */
    if(localStorage.getItem('token')){
        return (
            <Route { ...rest }
                   component={ (props) => (
                           <Component { ...props } />

                   )}

            />
        )
    }

    return (
        <Route { ...rest }
               component={ (props) => (
                   ( isAuthenticated )
                       ? ( <Component { ...props } /> )
                       : ( <Redirect to="/login" /> )
               )}

        />
    )
}
 export default PrivateRoute