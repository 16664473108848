import React, {useEffect} from 'react';

import Datatable from "../../commons/datatables/datatable";
import ModalCrearDatatableRow from "../../commons/modal-crear-datatable-row";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";
import {setLocalStorageData} from "../../../helpers/auth-helpers";
import {useHistory} from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {useForm, Controller} from "react-hook-form";
import {CircularProgress, MenuItem} from "@material-ui/core";
import {useMutation, useQuery} from "react-query";
import routes from "../../../services/api";
import {toast} from "react-toastify";
import {faPencilAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {startCloseLoading, startOpenLoading} from "../../../redux/actions/ui";
import {startLogin} from "../../../redux/actions/auth";
import ModalEditarDatatableRow from "../../commons/modal-editar-datatable-row";
import Swal from "sweetalert2";




function TablaClientes(props) {
    let history = useHistory();
    const {register, handleSubmit, control} = useForm()
    const {
        register: register2,
        errors: errors2,
        handleSubmit: handleSubmit2,
        setValue: setValue2,
        getValues: getValues2,
        control:control2
    } = useForm();

    const dispatch = useDispatch();

    // Queries

    // Queries
    /* const query = useQuery('todos', getTodos)
 */
    const {status, data, error, isFetching, refetch } = useQuery("getAllClientes", async () => {
        const res = await routes.clientes.getAll()
        return res.data;
    },{ refetchOnWindowFocus: false });
    const mutation_delete_cliente = useMutation((id) => routes.clientes.delete({id:id}), {
        onSuccess: (response) => {
            dispatch(startCloseLoading())

            toast.success("Cliente eliminado correctamente", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })

            let refetched = refetch()


        },
        onError: (error) => {
            dispatch(startCloseLoading())

            toast.error("Error al eliminar cliente ", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })


        }
    })

    // Mutations
    const mutation_create = useMutation((data) => routes.clientes.create(data), {
        onSuccess: (response) => {
            toast.success("Cliente creado correctamente", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })
            setOpenModal(false)
        },
        onError: (error) => {
            toast.error("Error al crear cliente", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })
            setOpenModal(false)

        },
        onMutate: () => {

        }
    })
    const mutation_editar = useMutation((data) => routes.clientes.editar(data), {
        onSuccess: (response) => {
            toast.success("Cliente editado correctamente", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })
            setOpenModalEditar(false)
            refetch() // funcion para reload manual del useQuery

        },
        onError: (error) => {
            toast.error("Error al editar cliente", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })
            setOpenModalEditar(false)

        },
        onMutate: () => {

        }
    })
    const mutation_get_cliente = useMutation((id_cliente) => routes.clientes.get(id_cliente), {
        onSuccess: (response,id_cliente) => {
            setOpenModalEditar(true)

            rellenarModalEditar(id_cliente,response.data)


        },
        onError: (error) => {
            toast.error("Error al editar cliente", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })

        },
        onMutate: () => {

        }
    })

    useEffect(  mutation_get_cliente.isLoading ? () => dispatch(startOpenLoading()) : () => dispatch(startCloseLoading()),[mutation_get_cliente.isLoading])


    function rellenarModalEditar(id_cliente,param_data){

        setValue2("id_cliente_editar", id_cliente)

        setValue2("nif_editar", param_data.nif, {
            shouldValidate: true
        })
        setValue2("nombre_editar", param_data.nombre, {
            shouldValidate: true
        })
        setValue2("pais_editar", param_data.pais, {
            shouldValidate: true
        })
        setValue2("ciudad_editar", param_data.ciudad, {
            shouldValidate: true
        })
        setValue2("direccion_editar", param_data.direccion, {
            shouldValidate: true
        })
        setValue2("codigo_postal_editar", param_data.codigo_postal, {
            shouldValidate: true
        })
        setValue2("contacto_nombre_editar", param_data.contacto_nombre, {
            shouldValidate: true
        })
        setValue2("contacto_email_editar", param_data.contacto_email, {
            shouldValidate: true
        })
        setValue2("contacto_tlf_editar", param_data.contacto_tlf, {
            shouldValidate: true
        })
        setValue2("metodo_entrega_defecto_editar", param_data.metodo_entrega_defecto, {
            shouldValidate: true
        })
        setValue2("metodo_pago_defecto_editar", param_data.metodo_pago_defecto, {
            shouldValidate: true
        })
        setValue2("iva_defecto_editar", param_data.iva_defecto, {
            shouldValidate: true
        })
    }

    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: true,
            }
        },

        {
            name: "nif",
            label: "NIF",
            options: {
                filter: true,
                sort: false,

            }

        },

        {
            name: "nombre",
            label: "Nombre / Razón Social",
            options: {
                filter: true,
                sort: true,

            }
        },

        {
            name: "contacto_nombre",
            label: "Persona de Contacto",
            options: {
                filter: true,
                sort: false,

            }

        },
        {
            name: "contacto_email",
            label: "Email",
            options: {
                filter: true,
                sort: false,

            }

        },
        {
            name: "contacto_tlf",
            label: "Teléfono",
            options: {
                filter: true,
                sort: false,

            }

        },
        {
            name: "ciudad",
            label: "Ciudad",

            options: {
                filter: true,
                sort: false,
                /*display: false,*/

            }

        },
        {
            name: "direccion",
            label: "Dirección",

            options: {
                filter: true,
                sort: false,
                /*display: false,*/

            }

        },


        {
            name: "ver",
            label: 'Ver obras',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {

                    return (
                        <>
                        <button onClick={ () => {


                            mutation_get_cliente.mutate(tableMeta.rowData[0]);



                        }} style={{color: '#333', border: 'none', background: 'transparent'}}

                                className="Btn-transparent-border-green Btn-small">

                            <FontAwesomeIcon style={{color: '#333', fontSize: '20px'}} icon={faPencilAlt}/>

                        </button>
                            <button onClick={() => {

                            setLocalStorageData(tableMeta.rowData[2], 'cliente_seleccionado_nombre')
                            setLocalStorageData(tableMeta.rowData[0], 'cliente_seleccionado_id')

                            history.push('/trabajos/' + props.anyo + '/obras')

                        }} style={{color: '#333', border: 'none', background: 'transparent'}}

                                className="Btn-transparent-border-green Btn-small">


                            <FontAwesomeIcon style={{color: '#333', fontSize: '20px'}} icon={faArrowRight}/>


                        </button>
                            <button title="Eliminar Capitulo" onClick={() => {

                                Swal.fire({
                                    title: 'Seguro que quieres eliminar el cliente ?',
                                    html: "<p class='my-3'>Se eliminara el cliente: " + tableMeta.rowData[2] + "</p>",
                                    icon: 'question',
                                    width: 700,
                                    maxWidth: '90%',
                                    padding: '3em',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Eliminar'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        dispatch(startOpenLoading())

                                        mutation_delete_cliente.mutate(tableMeta.rowData[0])
                                    }
                                })


                            }} style={{color: '#333', border: 'none', background: 'transparent'}}

                                    className="Btn-transparent-border-green Btn-small">

                                <FontAwesomeIcon style={{color: 'red', fontSize: '20px'}} icon={faTimes}/>

                            </button>
                            </>
                    );
                }
            },

        }
    ];

    const options = {
        selectableRows: 'none',


    }

    const onSubmit = data => {
        mutation_create.mutate(data)
    }
    const onSubmitEditar = data => {
        console.log(data)
        mutation_editar.mutate(data)
    }


    const [openModal, setOpenModal] = React.useState(false);
    const [openModalEditar, setOpenModalEditar] = React.useState(false);

function renderFormGenerico(submitFunction , register_param, control_param , setOpenModal_param,mutation_param, final_name_attr){

    return (
        <form onSubmit={submitFunction}>
            <input type="hidden" ref={register_param} name={`id_cliente${final_name_attr}`} />
            <h4 className="mt-1">Datos fiscales</h4>
            <h5>Datos necesarios del cliente o proveedor para la facturación.</h5>
            <div className="Grid">
                <TextField inputRef={register_param}
                           autoFocus
                           margin="dense"
                           name={`nif${final_name_attr}`}
                           label="NIF"
                           type="text"
                           fullWidth
                />
                <TextField inputRef={register_param}
                           autoFocus
                           margin="dense"
                           name={`nombre${final_name_attr}`}
                           label="Nombre o razón social"
                           type="text"
                           fullWidth
                />

                <TextField inputRef={register_param}
                           autoFocus
                           margin="dense"
                           name={`pais${final_name_attr}`}
                           label="País"
                           type="text"
                           fullWidth
                />
                <TextField inputRef={register_param}
                           autoFocus
                           margin="dense"
                           name={`provincia${final_name_attr}`}
                           label="Provincia"
                           type="text"
                           fullWidth
                />
                <TextField inputRef={register_param}
                           autoFocus
                           margin="dense"
                           name={`ciudad${final_name_attr}`}
                           label="Ciudad"
                           type="text"
                           fullWidth
                />
                <TextField inputRef={register_param}
                           autoFocus
                           margin="dense"
                           name={`direccion${final_name_attr}`}
                           label="Dirección"
                           type="text"
                           fullWidth
                />
                <TextField inputRef={register_param}
                           autoFocus
                           margin="dense"
                           name={`codigo_postal${final_name_attr}`}
                           label="Código Postal"
                           type="text"
                           fullWidth
                />

            </div>
            <h4>Datos de contacto</h4>
            <h5>Datos de la persona de contacto en la empresa.</h5>

            <div className="Grid col3">
                <TextField inputRef={register_param}
                           autoFocus
                           margin="dense"
                           name={`contacto_nombre${final_name_attr}`}
                           label="Nombre y Apellidos"
                           type="text"
                           fullWidth
                />
                <TextField inputRef={register_param}
                           autoFocus
                           margin="dense"
                           name={`contacto_email${final_name_attr}`}
                           label="Email"
                           type="email"
                           fullWidth
                />
                <TextField inputRef={register_param}
                           autoFocus
                           margin="dense"
                           name={`contacto_tlf${final_name_attr}`}
                           label="Teléfono"
                           type="text"
                           fullWidth
                />

            </div>
           {/* <h4>Métodos de entrega y pago por defecto</h4>
            <h5 style={{marginBottom: '40px'}}>Método de entrega de los documentos electrónicos y de pago
                predeterminados para este cliente </h5>
            <div className="Grid col3">

                <FormControl fullWidth>
                    <InputLabel>
                        Métodos de entrega por defecto
                    </InputLabel>
                    <Controller
                        control={control_param}
                        name={`metodo_entrega_defecto${final_name_attr}`}
                        defaultValue="adjunto_email"
                        as={
                            <Select defaultValue="adjunto_email">
                                <MenuItem value="adjunto_email">
                                    Adjunto por email
                                </MenuItem>


                            </Select>
                        }
                    />


                </FormControl>
                <FormControl fullWidth>
                    <InputLabel>
                        Métodos de pago por defecto
                    </InputLabel>
                    <Controller
                        control={control_param}
                        name={`metodo_pago_defecto${final_name_attr}`}
                        defaultValue="transferencia_bancaria"
                        as={
                            <Select defaultValue="transferencia_bancaria">
                                <MenuItem value="efectivo">En efectivo</MenuItem>
                                <MenuItem value="cargar_cuenta">Cargar en cuenta</MenuItem>
                                <MenuItem value="transferencia_bancaria">Transferencia bancaria</MenuItem>
                                <MenuItem value="tarjeta">Pago mediante tarjeta</MenuItem>
                            </Select>
                        }
                    />
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel>
                        Tipo IVA
                    </InputLabel>
                    <Controller
                        control={control_param}
                        name={`iva_defecto${final_name_attr}`}ç
                        defaultValue="21"
                        as={
                            <Select defaultValue="21">
                                <MenuItem value="exento">Sujeto Pasivo</MenuItem>
                                <MenuItem value="0">0.0% Tasa cero</MenuItem>
                                <MenuItem value="4">4.0% Tasa super reducida</MenuItem>
                                <MenuItem value="10">10.0% Tasa baja</MenuItem>
                                <MenuItem selected="selected" value="21">21.0% Estándar</MenuItem>
                            </Select>
                        }
                    />
                </FormControl>
            </div>*/}

            <div className="d-flex " style={{marginTop: '50px'}}>
                <Button variant="contained" color="secondary" onClick={() => setOpenModal_param(false)} type="button"
                        className="mr-auto d-block my-5">
                    Cancelar
                </Button>
                {mutation_param.isLoading
                    ?
                    <button
                        className="ml-auto mr-0 MuiButtonBase-root MuiButton-root MuiButton-containedd-block my-5 MuiButton-containedPrimary"
                        tabIndex="0" type="button">
                        <CircularProgress style={{color: 'white',fontSize:'10px',width: '20px',height : '20px'}}/>

                    </button>
                    :
                    <Button variant="contained" color="primary" type="submit" className="ml-auto d-block my-5">
                        Guardar

                    </Button>
                }
            </div>

        </form>
    )
}
    function FormCrear() {
        return renderFormGenerico(handleSubmit(onSubmit),register,control,setOpenModal,mutation_create,'')

    }
    function FormEditar() {
        return renderFormGenerico(handleSubmit2(onSubmitEditar),register2,control2,setOpenModalEditar,mutation_editar,'_editar')
    }


    return (
        <div className="mw-100">


            <ModalCrearDatatableRow title={'Crear nuevo cliente'} openModal={openModal} setOpenModal={setOpenModal}
                                    form={FormCrear()}/>
            <ModalEditarDatatableRow title={'Editar nuevo cliente'} openModal={openModalEditar} setOpenModal={setOpenModalEditar}
                                    form={FormEditar()}/>
          {/*  <ModalCrearDatatableRow title={'Editar cliente'} openModal={openModalEditar} setOpenModal={setOpenModalEditar}
            form={FormEditar()}/>*/}


            {data && !isFetching && !error && <Datatable titulo={''} data={data} options={options} columns={columns}/> }
            {
                !isFetching && !error && !data &&  <h3 className="text-center my-5 py-5 ">No hay datos</h3>
            }
            {
                isFetching &&
                <div className="d-flex justify-content-center align-items-center my-5 py-5"><CircularProgress/></div>

            }
            {
                error && <h3 className="text-center my-5 py-5 " style={{color:'red'}}>Ha ocurrido un error {error.message}</h3>
            }

        </div>
    );

}





export default TablaClientes;