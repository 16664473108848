import React, {useContext} from 'react';

import {AccountDropdown} from "tabler-react";
import user from "../../../assets/images/user.jpg";
import {AuthDataContext} from "../../../context/auth-context";
import {useDispatch} from "react-redux";
import { startLogout} from "../../../redux/actions/auth";

function DropDown() {
    /*const { dispatch} = useContext(AuthDataContext);*/
    const dispatch = useDispatch();

    function logout(){
       /* dispatch({
            type: 'logout',
        });*/

        dispatch( startLogout(  ) );

    }
    return (
        <AccountDropdown
            avatarURL={user}
            name="Jacinto Castro"
            description="Administrador"
            options={[
                "profile",
                { icon: "settings", value: "Settings", to: "/settings" },
                "mail",
                "message",
                "divider",
                "help",
                { icon: "logout", value: "Cerrar sesión",  onClick: ()=>{ logout()} },
            ]}
        />
    );
}

export default DropDown;