
export const types = {

    uiOpenModal: '[ui] Open modal',
    uiCloseModal: '[ui] Close modal',
    uiOpenLoading: '[ui] Open Loading',
    uiCloseLoading: '[ui] Close Loading',
    uiOpenSnackbar: '[ui] Open Snackbar',
    uiCloseSnackbar: '[ui] Close Snackbar',


    eventSetActive: '[event] Set Active',
    eventAddNew: '[event] Add new',
    eventClearActiveEvent: '[event] Clear active event',
    eventUpdated: '[event] Event updated',
    eventDeleted: '[event] Event deleted',


    authCheckingFinish: '[auth] Finish checking login state',
    authStartLogin: '[auth] Start login',
    authLogin: '[auth] Login',
    authStartRegister: '[auth] Start Register',
    authStartStartTokenRenew: '[auth] Start token renew',
    authLogout: '[auth] Logout',
    authAuthenticate: '[auth] Authenticate',

}