import React, {useEffect, useState} from 'react';

import moment from 'moment';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Controller, useFormContext} from "react-hook-form";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import {CircularProgress, MenuItem} from "@material-ui/core";
import {useMutation} from "react-query";
import routes from "../../../../services/api";
import {startCloseLoading} from "../../../../redux/actions/ui";
import {toast} from "react-toastify";
import '../../../../assets/forms-pagina.scss'

function CrearFacturaStep1(props) {
    const methods = useFormContext(); /*con el useformcontext los metodos del react hook form serán comaprtidos dentro del provider,
      así no hay que pasarlo por props a todos los componentes formulario*/
    const {reset, register, control, setValue} = methods;
    /*Uso el reset para ponerle los datos del objeto global(dataform) al formulario*/
    useEffect(() => {
        reset({...props.dataForm}, {errors: true});
    }, []);

    const email_cliente_mutation = useMutation((data) => routes.clientes.getEmail(data), {
        onSuccess: (response) => {

            setValue('email_enviar', response.data || "")

        },
        onError: (error) => {


        },
        onMutate: () => {

        }
    })
    const getEmailCliente = data => {
        email_cliente_mutation.mutate(data.target.value)
        setValue('cliente_id', data.target.value)

    }

    return (
        <>

            <>


                <div className="Row-flex  Columns3">
                    <FormControl fullWidth>
                        <InputLabel>
                            Idioma
                        </InputLabel>

                        <Controller
                            control={control}
                            name="idioma"
                            defaultValue="es"
                            as={
                                <Select>
                                    <MenuItem value="es">Castellano</MenuItem>
                                    <MenuItem value="ca">Catalán</MenuItem>
                                </Select>
                            }
                        />
                    </FormControl>

                </div>
                <div className="Row-flex  Columns3">
                    {/*  <h4 className="Titulo-seccion-form ">
                        Cliente
                    </h4>*/}
                    <FormControl fullWidth>
                        {props.lista_clientes_fetching
                            ?
                            <CircularProgress/>
                            :
                            <>
                        <InputLabel>
                            Cliente
                        </InputLabel>

                        <Controller
                            control={control}
                            name="cliente_id"
                            defaultValue={props.dataForm.cliente_id || ""}

                            render={() => (
                                <Select onChange={getEmailCliente} defaultValue={props.dataForm.cliente_id || ""}>
                                    <MenuItem value="">Seleccionar</MenuItem>
                                    {props.lista_clientes.map((item, i) => <MenuItem key={i}
                                                                                     selected={props.dataForm.cliente_id === item.id }
                                                                                     value={item.id}>{item.nombre}</MenuItem>)}

                                </Select>
                            )}
                        /> </>}
                    </FormControl>
                    <div>
                            <TextField inputRef={register} name="email_enviar" autoFocus fullWidth
                                       margin="normal" label={'Enviar a:'}
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                            />

                    </div>
                    <div>

                        <TextField inputRef={register} name="obra" autoFocus fullWidth
                                   margin="normal" label={'Obra:'}
                        />
                    </div>
                </div>


                <div className="Row-flex Columns3">

                    <FormControl fullWidth>
                        <InputLabel>
                            Tipo IVA
                        </InputLabel>

                        <Controller
                            control={control}
                            name="iva"
                            defaultValue="21"
                            as={
                                <Select>
                                    <MenuItem value="exento">Sujeto Pasivo</MenuItem>
                                    <MenuItem value="0">0.0% Tasa cero</MenuItem>
                                    <MenuItem value="4">4.0% Tasa super reducida</MenuItem>
                                    <MenuItem value="10">10.0% Tasa baja</MenuItem>
                                    <MenuItem selected="selected" value="21">21.0% Estándar</MenuItem>
                                </Select>
                            }
                        />
                    </FormControl>
                    <div>

                        <TextField inputRef={register} name="fecha" autoFocus fullWidth
                                   margin="normal" type='date' defaultValue={moment().format('YYYY-MM-DD')}
                                   label={'Fecha'}
                        />
                    </div>
                    <div>

                        <TextField inputRef={register} name="referencia_comprador" autoFocus fullWidth
                                   margin="normal" label={'Referencia'}
                        />
                    </div>
                </div>

                <h5 className="mb-3">Pedido</h5>
                <div className="Row-flex Columns3 mt-0">
                    <div>

                        <TextField inputRef={register} name="numero_pedido" autoFocus fullWidth
                                   margin="normal" label={'Número de pedido'}
                        />
                    </div>
                    <div>

                        <TextField inputRef={register} name="fecha_pedido" autoFocus fullWidth
                                   margin="normal" type='date' InputLabelProps={{
                            shrink: true,
                        }}
                                   label={'Fecha del pedido'} defaultValue=""
                        />
                    </div>
                    <div>

                        <TextField inputRef={register} name="numero_pedido_cliente" autoFocus fullWidth
                                   margin="normal" label={'Núm. de pedido del cliente'}
                        />
                    </div>
                </div>
                <h5 className="mb-3">Contrato</h5>
                <div className="Row-flex  mt-0">
                    <div>

                        <TextField inputRef={register} name="numero_contrato" autoFocus fullWidth
                                   margin="normal" label={'Número contrato'}
                        />
                    </div>
                    <div>

                        <TextField inputRef={register} name="fecha_contrato" autoFocus fullWidth
                                   margin="normal" type='date' InputLabelProps={{
                            shrink: true,
                        }}
                                   label={'Fecha del contrato'} defaultValue=""
                        />
                    </div>

                </div>

                {/*variant="outlined"
*/}


                <div className="d-flex justify-content-between">
                    {/* <Button variant="contained" color="secondary" type="submit">
                    <Link to="/home"><span >Cancelar
  </span></Link>
                    </Button>

                    <Button variant="contained" color="primary" type="submit">
                        <span>
                                {cargando ? <CircularProgress style={{'color': '#fff'}}/> : 'Guardar'}
                                </span>
                    </Button>*/}

                </div>
            </>

        </>
    );
}

export default CrearFacturaStep1;