import React, {Component, useState} from 'react';
import CardMenu from "./card-menu";
import './style.scss'
import {faFileInvoice, faFileInvoiceDollar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from 'react-router-dom';

function InicioGrupo2(props) {
    function renderMenu() {
        return (
            <>
                <li className="nav-item"><a aria-current="page" className="nav-link active active"

                                            href="/"><i className="fe fe-home"></i> Inicio</a></li>
                <li className="nav-item"><a className="nav-link"><i className="fe fe-users"/> Clientes</a>
                </li>
                <li className="nav-item"><a className="nav-link"><i
                    className="fe fe-calendar"/> Proveedores</a></li>
                <li className="nav-item"><a className="nav-link"><i
                    className="fe fe-file"/> Presupuestos</a></li>
                <li className="nav-item"><a className="nav-link"><i
                    className="fe fe-file-text"/> Facturas</a></li>
            </>
        )
    }

    return (
        <>
           {/* <NavbarMenu menu={renderMenu()}/>*/}

            <div className="container Inicio-grupo2">
                <Breadcrumbs aria-label="breadcrumb">

                    <Typography color="textPrimary">Inicio</Typography>

                </Breadcrumbs>
                <div className="row">
                    <div className="col-12  d-flex flex-wrap justify-content-center">
                        <CardMenu link={'/trabajos'} title="Trabajos"
                                  description="Sección donde se gestionan los datos y documentos de los proyectos antes de ser aceptados">
                            <FontAwesomeIcon icon={faFileInvoice}/>
                        </CardMenu>
                        <CardMenu link={'/cuentas'} title="Cuentas"
                                  description="Sección donde se gestionan los datos y documentos de los proyectos una vez han sido aceptados o acabados de ser aceptados">
                            <FontAwesomeIcon icon={faFileInvoiceDollar}/>

                        </CardMenu>


                    </div>

                </div>
            </div>
        </>
    );
}

export default InicioGrupo2;