import Footer from "./components/commons/footer";
import AuthDataProvider from "./context/auth-context";
import NavigationProvider from "./context/navigation-context";
import { ReactQueryDevtools } from 'react-query/devtools'
import {
    QueryClient,
    QueryClientProvider,
} from 'react-query'
import { store } from './redux/store/store';
import {Provider} from "react-redux";

import "tabler-react/dist/Tabler.css";

import {Backdrop, CircularProgress} from "@material-ui/core";
import BackdropMod from "./components/commons/backdrop";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import './App.scss';
import './assets/forms.scss'
import {AppRouter} from "./routers/AppRouter";

const queryClient = new QueryClient()

toast.configure()
function App() {


    return (
        <Provider store={ store }>
        <BackdropMod/>
        <NavigationProvider>
                <div className="App">
                    <QueryClientProvider client={queryClient}>
                        <AppRouter/>

                    </QueryClientProvider>
                </div>
            </NavigationProvider>
        </Provider>
    );
}

export default App;
