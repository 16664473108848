import React, {useEffect, useState} from 'react';

import {Grid, Page} from "tabler-react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import {Link as RouterLink, useHistory} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CrearPresupuestoStep1 from "./form/step1";
import VerticalLinearStepper from "../../commons/stepper/vertical";
import HorizontalLabelPositionBelowStepper from "../../commons/stepper/horizontal";
import CrearPresupuestoStep2 from "./form/step2";
/*import {useForm} from "../../hooks/useForm";*/
import {useForm, FormProvider} from "react-hook-form";
import CrearPresupuestoStep3 from "./form/step3";
import {useMutation, useQuery} from "react-query";
import routes from "../../../services/api";
import {toast} from "react-toastify";
import {startCloseLoading, startOpenLoading} from "../../../redux/actions/ui";
import {useDispatch} from "react-redux";
import CrearPresupuestoStep4 from "./form/step4";
import CrearPresupuestoStep5 from "./form/step5";


function CrearPresupuesto(props) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [dataForm, setDataForm] = React.useState({});
    const methods = useForm();
    const {watch, errors, getValues} = methods;
    const dispatch = useDispatch();
    let history = useHistory()
    const [sectionsTitulos, setSectionsTitulos] = useState([])


    /*
        useEffect(() => {
            console.log("FORM CONTEXT", watch(), errors);
        }, [watch, errors]);*/

    const {
        status,
        data = [],
        error,
        isFetching: lista_clientes_fetching,
        refetch
    } = useQuery("getAllNombresClientes", async () => {
        const res = await routes.clientes.getAllNombresClientes()
        return res.data;
    }, {refetchOnWindowFocus: false});

    const mutation_create_presupuesto = useMutation((data) => routes.presupuestos.create(data), {
        onSuccess: (response) => {
            dispatch(startCloseLoading())

            toast.success("Presupuesto creada correctamente", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })
            history.push('/presupuestos')


        },
        onError: (error) => {
            dispatch(startCloseLoading())

            toast.error("Error al crear presupuesto", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })
            setActiveStep(0);


        },
        onMutate: () => {

        }
    })

    function calcularImporte(param) {
        let importe = 0;
        console.log(param)
        if (!param) {
            return 'no hay lineas'


        }

        param.forEach(element => {
            if (!element.lineas) {
                return 'no hay lineas'

            }
            if (element.lineas) {
                element.lineas.forEach(lineas => {
                    importe = importe + (lineas.cantidad * lineas.precio)

                })
            }

        })

        return importe

    }

    const onSubmit = data => {
        data.base_imponible = calcularImporte(data.seccion)


        /*--------------
        CONTROL ERRORES
        * -----------*/
        if (erroresFormulario(data)) return


        /*--------
        * */
        dispatch(startOpenLoading())


        mutation_create_presupuesto.mutate(data)
    }

    const erroresFormulario = (data) => {
        if (!data.cliente_id) {
            toast.error('Debes seleccionar un cliente vinculado a esta presupuesto', {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 6000
            })
            setActiveStep(0);

            return true
        }
        if (!data.email_enviar) {
            toast.error('Debes indicar un email del cliente a enviar', {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 6000
            })
            setActiveStep(0);

            return true
        }
        if (data.base_imponible == 'no hay lineas') {
            setActiveStep(1);

            toast.error('No hay lineas en la presupuesto', {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 6000
            })
            return true
        }

        if (data.metodo_pago === 'transferencia_bancaria' && !data.cuenta) {
            toast.error('Se debe seleccionar una cuenta si el metodo de pago es  transferencia bancaria', {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 6000
            })
            setActiveStep(2);

            return true

        }

        return false


    }

    const handleNext = (e) => {
        e.preventDefault()
        console.log(getValues())

        setDataForm(prevState => {
            // Object.assign también funcionaría
            return {...prevState, ...getValues()};
        });


        setActiveStep((prevActiveStep) => prevActiveStep + 1);


        if (activeStep === 4) {

            onSubmit(dataForm)
        }
    };

    const handleBack = (e) => {
        e.preventDefault()
        setDataForm(prevState => {
            // Object.assign también funcionaría
            return {...prevState, ...getValues()};
        });
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const crearNuevaLinea = (seccion_key) => {

        /*setSections([...sections,[]])*/
        let copy = [...sections];
        copy[seccion_key].push([])
        setSections(copy)

    }


    const [sections, setSections] = useState([])

    const crearNuevaSeccion = () => {
        let copy = [...sections];
        copy.push([])
        setSections(copy)


        /* setSections([...sections,[]])*/

    }


    return (
        <Page.Content title="Crear Presupuesto" className="Crear-Presupuesto">
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" component={RouterLink} to="/inicio">
                    Inicio
                </Link>
                <Link color="inherit" component={RouterLink} to="/presupuestos">
                    Presupuestos
                </Link>
                <Typography color="textPrimary">Crear Presupuesto</Typography>

            </Breadcrumbs>
            <Grid.Row>
                <Grid.Col className="Bg-white Tarjeta-Principal px-0">

                    <FormProvider {...methods}>
                        <VerticalLinearStepper activeStep={activeStep}
                                               steps={['Datos Generales', 'Capítulos', 'Formas de Pago', 'Descuentos y cargos extra', 'Documentos adicionales']}
                                               handleNext={handleNext} handleBack={handleBack}
                                               step1={<CrearPresupuestoStep1
                                                   lista_clientes_fetching={lista_clientes_fetching}
                                                   lista_clientes={data} dataForm={dataForm}/>}
                                               step2={<CrearPresupuestoStep2
                                                   sectionsTitulos={sectionsTitulos}
                                                   setSectionsTitulos={setSectionsTitulos}
                                                   crearNuevaLinea={crearNuevaLinea}
                                                   dataForm={dataForm}
                                                   sections={sections} setSections={setSections}
                                                   crearNuevaSeccion={crearNuevaSeccion}/>}
                                               step3={<CrearPresupuestoStep3 dataForm={dataForm}/>}
                                               step4={<CrearPresupuestoStep4 dataForm={dataForm}/>}
                                               step5={<CrearPresupuestoStep5 dataForm={dataForm}/>}/>

                    </FormProvider>


                </Grid.Col>
            </Grid.Row>
        </Page.Content>
    );
}

export default CrearPresupuesto;