import React from 'react';
import {Page,  Grid} from "tabler-react";
import TablaDocumentosObra from "./tabla-documentos-obra";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import {Link as RouterLink, useParams} from "react-router-dom";
import {getLocalStorageData} from "../../../helpers/auth-helpers";

function DocumentosObra(props) {
    let { anyo } = useParams();

    return (
        <>

            <Page.Content title={`Documentos `}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit"  component={RouterLink} to="/">
                        Inicio
                    </Link>
                    <Link color="inherit"  component={RouterLink} to="/trabajos">
                        Trabajos por año
                    </Link>
                    <Link color="inherit"  component={RouterLink} to="/trabajos/2020">
                        {anyo}
                    </Link>
                    <Link color="inherit"  component={RouterLink} to="/trabajos/2020">
                        Obras de {getLocalStorageData('cliente_seleccionado_nombre')}
                    </Link>
                    <Link color="inherit"  component={RouterLink} to="/trabajos/2020">
                         {getLocalStorageData('obra_nombre')}

                    </Link>

                    <Typography color="textPrimary">Documentos</Typography>

                </Breadcrumbs>
                <Grid.Row>
                    <Grid.Col className="Bg-white">

                        <TablaDocumentosObra />
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </>

    );
}

export default DocumentosObra;