import React, {useEffect} from 'react';
import {Page,  Grid} from "tabler-react";
import TablaObras from "./tabla-obras";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import {Link as RouterLink,  useParams} from "react-router-dom";
import {getLocalStorageData, setLocalStorageData} from "../../../helpers/auth-helpers";

function Obras(props) {
    let { anyo } = useParams();



    return (
        <>

            <Page.Content title={`Obras de ${getLocalStorageData('cliente_seleccionado_nombre')}`}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit"  component={RouterLink} to="/">
                        Inicio
                    </Link>
                    <Link color="inherit"  component={RouterLink} to="/trabajos">
                        Trabajos por año
                    </Link>
                    <Link color="inherit"  component={RouterLink} to="/trabajos/2020">
                        {anyo}
                    </Link>

                    <Typography color="textPrimary">Obras de {getLocalStorageData('cliente_seleccionado_nombre')}</Typography>

                </Breadcrumbs>
                <Grid.Row>
                    <Grid.Col className="Bg-white">

                        <TablaObras />
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </>

    );
}

export default Obras;