import React from 'react';
import {Page,  Grid} from "tabler-react";
import TablaCuentasAnyo from "./tabla-cuentas-anyo";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import {Link as RouterLink} from "react-router-dom";

function Cuentas(props) {

    return (
        <>

            <Page.Content title="Cuentas por año">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit"  component={RouterLink} to="/">
                        Inicio
                    </Link>

                    <Typography color="textPrimary">Cuentas</Typography>

                </Breadcrumbs>
                <Grid.Row>
                    <Grid.Col className="Bg-white">

                        <TablaCuentasAnyo/>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </>

    );
}

export default Cuentas;