import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../assets/images/logo.png'
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {startLogin} from "../../redux/actions/auth";
import {useForm} from "react-hook-form";
import {startCloseLoading, startOpenLoading} from "../../redux/actions/ui";
import './style.scss'


const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
        fontSize: '16px',
        padding: '7px'
    },
}));

export default function SignIn() {
    const classes = useStyles();
    const {register, handleSubmit, formState: {isSubmitting}} = useForm()

    const history = useHistory()
    /* const {state, dispatch} = useContext(AuthDataContext);*/
    const dispatch = useDispatch();

    let errors = {}


    const onSubmit = async data => {

        /* setLocalStorageData('hola','token')*/
        dispatch(startOpenLoading())

        await dispatch(startLogin(data));
        dispatch(startCloseLoading())

    }
    return (
        <div className="Bg-Transition">

            <Container className="Login" component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <img src={logo} alt="logo"/>
                    <Typography component="h1" variant="h5">
                        Iniciar Sesión
                    </Typography>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            inputRef={register} label="Email"
                            name="email"
                            autoComplete="email"
                            style={{marginTop: '1.3rem!important', marginBottom: '1.3rem!important'}}
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            inputRef={register} name="password"
                            label="Contraseña"
                            type="password" style={{marginTop: '1.3rem!important', marginBottom: '1.1rem!important'}}

                            id="password"
                            autoComplete="current-password"
                        />

                        {/*    <FormControlLabel className="mt-1"
                                          control={<Checkbox value="remember" color="primary"/>}
                                          label="Recordar usuario"
                        />*/}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Iniciar Sesión
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    ¿Has olvidado la contraseña?
                                </Link>
                            </Grid>

                        </Grid>
                    </form>
                </div>

            </Container>
        </div>

    );
}