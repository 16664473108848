import React, {createContext, useReducer, useEffect} from "react";

export const AuthDataContext = createContext(null);

const initialAuthData = {};

function AuthDataReducer(state, action) {
    switch (action.type) {
        case 'login':

            return {
                ...action.data,
                logged : true
            };

        case 'logout':

            return {
                logged : false
            };
        case 'update_auth_storage':

            return {
                ...state,
                ...action.data
            };
        case 'delete_auth_storage':

            return {
                state : initialAuthData
            };
        default:
            return state
    }
}


const init = () => {
    return JSON.parse(localStorage.getItem('user')) || { logged: false }; // el || hace que si no existe devuelva {logged : false}
}
const AuthDataProvider = props => {

    /*EL init es la funcion inicial que le pasará la data al initialAuthData, en este caso
    * le devolverá los datos devueltos del localstorage como initialAuthData*/
    const [state, dispatch] = useReducer(AuthDataReducer, initialAuthData,init);

    /*Cada vez que cambia el estado del auth context(al hacer login y logout), lo guardo en el localstorage*/
    useEffect(() => {

        localStorage.setItem( 'user', JSON.stringify(state) );

    }, [state]);
    return (
        <AuthDataContext.Provider value={{state, dispatch}}>
            {props.children}
        </AuthDataContext.Provider>
    );
};

export default AuthDataProvider;