import React, {useEffect} from 'react';

import TextField from "@material-ui/core/TextField";
import '../../../../assets/forms-pagina.scss'
import {useFormContext} from "react-hook-form";

function CrearPresupuestoStep5(props) {
    const methods = useFormContext();
    const { reset, register,control } = methods;
    useEffect(() => {
        reset({ ...props.dataForm}, { errors: true });
    }, []);
    return (
        <>

            <>

                <h5 className="mb-3">Adjuntar documento adicional</h5>
                <div className="Row-flex Columns3 mt-0">
                    <div>

                        <TextField inputRef={register}  name="documento_adicional" autoFocus fullWidth
                                   margin="normal" label={'Documento adicional'}
                        />
                    </div>
                    <div>


                    </div>
                    <div>


                    </div>
                </div>



                <div className="d-flex justify-content-between">
                    {/* <Button variant="contained" color="secondary" type="submit">
                    <Link to="/home"><span >Cancelar
  </span></Link>
                    </Button>

                    <Button variant="contained" color="primary" type="submit">
                        <span>
                                {cargando ? <CircularProgress style={{'color': '#fff'}}/> : 'Guardar'}
                                </span>
                    </Button>*/}

                </div>
            </>

        </>
    );
}

export default CrearPresupuestoStep5;