import {useContext} from "react";
import {AuthDataContext} from "../context/auth-context";
import {getLocalStorageData} from "./auth-helpers";

export function intToHour(param){
    let hora ='';
    if(param<10){
        hora = '0'+param+':00'
    }else{
        hora = param+':00'
    }
    return hora
}

export function euroFormat(valor){
    const formatterEuro = new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'EUR'
    })
    return  formatterEuro.format(valor)
}

export function calcularPrecioMasIva(base_imponible,iva){
    /*10 ---- 100%
    * x ----- 21%
    * */
    base_imponible = parseFloat(base_imponible)
    iva = parseInt(iva)
    if(isNaN(iva) || iva === 0){
        return base_imponible
    }

    return (base_imponible * iva / 100) + base_imponible
}

export function returnProvincia(cpostal){
    let cp_provincias = {
        1: "Álava",
        2: "Albacete",
        3: "Alicante",
        4: "Almería",
        5: "Ávila",
        6: "Badajoz",
        7: "Baleares",
        8: "Barcelona",
        9: "Burgos",
        10: "Cáceres",
        11: "Cádiz",
        12: "Castellón",
        13: "Ciudad Real",
        14: "Córdoba",
        15: "Coruña",
        16: "Cuenca",
        17: "Gerona",
        18: "Granada",
        19: "Guadalajara",
        20: "Guipúzcoa",
        21: "Huelva",
        22: "Huesca",
        23: "Jáen",
        24: "León",
        25: "Lérida",
        26: "La Rioja",
        27: "Lugo",
        28: "Madrid",
        29: "Málaga",
        30: "Murcia",
        31: "Navarra",
        32: "Orense",
        33: "Asturias",
        34: "Palencia",
        35: "Las Palmas",
        36: "Pontevedra",
        37: "Salamanca",
        38: "Santa Cruz de Tenerife",
        39: "Cantabria",
        40: "Segovia",
        41: "Sevilla",
        42: "Soria",
        43: "Tarragona",
        44: "Teruel",
        45: "Toledo",
        46: "Valencia",
        47: "Valladolid",
        48: "Vizcaya",
        49: "Zamora",
        50: "Zaragoza",
        51: "Ceuta",
        52: "Melilla"
};
    if(cpostal.length == 5 && cpostal <= 52999 && cpostal >= 1000)
        return cp_provincias[parseInt(cpostal.substring(0,2))];
    else
        return "---";
}

