import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import './style.scss'
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));



function getStepContent(step, props) {
    switch (step) {
        case 0:
            return (props.step1);
        case 1:
            return (props.step2)
        case 2:
            return (props.step3)
        case 3:
            return (props.step4)
        case 4:
            return (props.step5)
        default:
            return 'Unknown step';
    }
}

export default function VerticalLinearStepper(props) {
    const classes = useStyles();
    const steps = props.steps;



    return (
        <div className={classes.root}>
            <Stepper activeStep={props.activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                            <div>{getStepContent(index, props)}</div>
                            <div className={classes.actionsContainer}>
                                <div className="d-flex justify-content-between">
                                    <Button variant="contained" color="secondary" /* disabled={activeStep === 0}*/ onClick={props.handleBack} className={classes.backButton}>
                                        {props.activeStep === 0
                                            ?
                                            <Link to="/inicio">
                                                <span>Cancelar</span>
                                            </Link>
                                            :
                                            <span> Volver</span>
                                        }
                                    </Button>

                                        <Button variant="contained" color="primary" onClick={props.handleNext} type="button">
                                            {props.activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
                                        </Button>


                                </div>
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>

        </div>
    );
}