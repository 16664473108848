import React, {useContext, useEffect} from "react";
import {Route, Switch, Redirect} from "react-router-dom";

import Dashboard from "../components/dashboard";

import LoginMaterialUI from "../components/login";
import {AuthDataContext} from "../context/auth-context";
import useCheckLogged from "../hooks/useCheckLogged";
import {getLocalStorageData} from "../helpers/auth-helpers";
import {HashRouter, BrowserRouter} from "react-router-dom";
import DashboardRoutes from "./DashboardRoutes";
import {PublicRoute} from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import {useDispatch, useSelector} from "react-redux";
import {startCheckIsAuthenticated} from "../redux/actions/auth";
import {startCloseLoading, startOpenLoading} from "../redux/actions/ui";
import Footer from "../components/commons/footer";
import {QueryClientProvider} from "react-query";
import CambiarEstadoFactura from "../components/facturas/cambiar_estado_factura";
import CambiarEstadoPresupuesto from "../components/presupuestos/cambiar_estado_presupuesto";


export const AppRouter = () => {

    /*const { state } = useContext(AuthDataContext);*/
    const dispatch = useDispatch();
    const {checking, name} = useSelector(state => state.auth);

    useEffect(() => {

        dispatch(startOpenLoading())

        dispatch(startCheckIsAuthenticated()).then(
            (res) => {
                /*Hago que si hay un token pero me devuelve un 404 me eche de la app borrando el token localstorage
                * ya que al no haber token en el localstorage me echa directamente*/
                if(res==='no auth'){
                    localStorage.clear();
                    window.location.reload()
                }


                dispatch(startCloseLoading())

            }
        );

    }, [dispatch])

    /*SI SE ESTÁ COMPROBANDO EL CHECKING AUTH NO DEVUELVO NADA */
      if ( checking ) {
          return null;
      }
    return (

        <>
        <BrowserRouter>

            <Switch>

                {/*GREPOL*/}
                {/* <PrivateRoute exact path='/inicio' component={Dashboard}/>*/}
                {/*--------------------*/}
                {/*GRUPO 2*/}
                <Route
                    exact
                    path="/factura/:estado/:uniqid"
                    component={CambiarEstadoFactura}
                />
                <Route
                    exact
                    path="/presupuesto/:estado/:uniqid"
                    component={CambiarEstadoPresupuesto}
                />
                <PublicRoute
                    exact
                    path="/login"
                    component={LoginMaterialUI}
                    isAuthenticated={!!name}
                />

                <PrivateRoute
                    path="/"
                    component={DashboardRoutes}
                    isAuthenticated={!!name}
                />

                {/*----------*/}


                {/* <Route  exact path='/registrarse' component={SignUp}/>
        <Route  exact path='/recuperar-contrasenya-email' component={ForgetPasswordSolicitarEmail}/>
        <Route  exact path='/recuperar-contrasenya-cambiar/:token' component={ForgetPasswordCambiarPass}/>*/}
                {/*  <PrivateRoute  exact path='/home' component={Home}/>
       */}
            </Switch>
        </BrowserRouter>
            <Footer/>

        </>
    );
}