import axios from 'axios'

const requestHelper = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})
const routes = {
    clientes: {
        getAll: () => requestHelper({
            url: 'clientes/getAll',
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        delete: (data) => requestHelper({
            url: 'clientes/delete',
            method: 'post',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        getAllNombresClientes: () => requestHelper({
            url: 'clientes/getAllNombresClientes',
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        get: (id) => requestHelper({
            url: 'clientes/get/'+id,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        create: (data) => requestHelper({
            url: 'clientes/create',
            method: 'post',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        getEmail: (id) => requestHelper({
            url: 'clientes/getEmail/'+id,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        editar: (data) => requestHelper({
            url: 'clientes/edit',
            method: 'put',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
    },
    articulo_capitulo: {
        getAll: (idioma) => requestHelper({
            url: 'articulo_capitulo/getAll/'+idioma,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),

        get: (id) => requestHelper({
            url: 'articulo_capitulo/get/'+id,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        create: (data) => requestHelper({
            url: 'articulo_capitulo/create',
            method: 'post',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        delete: (data) => requestHelper({
            url: 'articulo_capitulo/delete',
            method: 'post',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        editar: (data) => requestHelper({
            url: 'articulo_capitulo/edit',
            method: 'put',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
    },
    articulo_partida: {
        getAll: (id,idioma) => requestHelper({
            url: 'articulo_partida/getAll/'+id+'/'+idioma,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),

        get: (id) => requestHelper({
            url: 'articulo_partida/get/'+id,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        delete: (data) => requestHelper({
            url: 'articulo_partida/delete',
            method: 'post',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        create: (data) => requestHelper({
            url: 'articulo_partida/create',
            method: 'post',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),

        editar: (data) => requestHelper({
            url: 'articulo_partida/edit',
            method: 'put',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
    },
    facturas: {
        getAll: () => requestHelper({
            url: 'facturas/getAll',
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        get: (id) => requestHelper({
            url: 'facturas/get/'+id,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        pdf: (id) => requestHelper({
            url: 'facturas/factura_pdf/'+id,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        create: (data) => requestHelper({
            url: 'facturas/create',
            method: 'post',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        cambiar_estado_manual: (data) => requestHelper({
            url: 'facturas/cambiar_estado_manual',
            method: 'post',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        duplicar_factura: (data) => requestHelper({
            url: 'facturas/duplicar_factura',
            method: 'post',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        delete: (data) => requestHelper({
            url: 'facturas/delete',
            method: 'post',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        cambiar_estado: (data) => requestHelper({
            url: 'facturas/cambiar_estado',
            method: 'post',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        enviar_factura: (id) => requestHelper({
            url: 'facturas/enviar_factura/'+id,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        editar: (data) => requestHelper({
            url: 'facturas/edit',
            method: 'put',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
    },
    presupuestos: {
        getAll: () => requestHelper({
            url: 'presupuestos/getAll',
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        delete: (data) => requestHelper({
            url: 'presupuestos/delete',
            method: 'post',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        get: (id) => requestHelper({
            url: 'presupuestos/get/'+id,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        pdf: (id) => requestHelper({
            url: 'presupuestos/presupuesto_pdf/'+id,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        create: (data) => requestHelper({
            url: 'presupuestos/create',
            method: 'post',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        cambiar_estado_manual: (data) => requestHelper({
            url: 'presupuestos/cambiar_estado_manual',
            method: 'post',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        cambiar_estado: (data) => requestHelper({
            url: 'presupuestos/cambiar_estado',
            method: 'post',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        crear_factura_presupuesto: (data) => requestHelper({
            url: 'presupuestos/crear_factura_presupuesto',
            method: 'post',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        enviar_presupuesto: (id) => requestHelper({
            url: 'presupuestos/enviar_presupuesto/'+id,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        editar: (data) => requestHelper({
            url: 'presupuestos/edit',
            method: 'put',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
    },
    cuenta_bancaria: {
        getAll: () => requestHelper({
            url: 'cuenta_bancaria/getAll',
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        get: (id) => requestHelper({
            url: 'cuenta_bancaria/get/'+id,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        create: (data) => requestHelper({
            url: 'cuenta_bancaria/create',
            method: 'post',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        editar: (data) => requestHelper({
            url: 'cuenta_bancaria/edit',
            method: 'put',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
    },
    seccion_linea_factura: {
        getAll: (id_factura) => requestHelper({
            url: 'seccion_linea_factura/getAll/'+id_factura,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        get: (id) => requestHelper({
            url: 'seccion_linea_factura/get/'+id,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        create: (data) => requestHelper({
            url: 'seccion_linea_factura/create',
            method: 'post',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        editar: (data) => requestHelper({
            url: 'seccion_linea_factura/edit',
            method: 'put',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
    },
    linea_factura: {
        getAll: (id_factura) => requestHelper({
            url: 'linea_factura/getAll/'+id_factura,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        get: (id) => requestHelper({
            url: 'linea_factura/get/'+id,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        create: (data) => requestHelper({
            url: 'linea_factura/create',
            method: 'post',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }),
        editar: (data) => requestHelper({
            url: 'linea_factura/edit',
            method: 'put',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
    },
    auth: {
        login: (data) => requestHelper({
            url: 'auth/login',
            method: 'post',
            data
        }),
        checkAuthenticated: (token) => requestHelper({
            url: 'auth/checkAuthenticated',
            method: 'get',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }),
    }
}

export default routes