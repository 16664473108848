import {post, get} from '../../helpers/fetch';
import {types} from '../../types/types';
import Swal from 'sweetalert2';
import routes from "../../services/api";
import {toast} from "react-toastify";


export const startLogin = (data) => async (dispatch) => {

    try {

        const response = await routes.auth.login(data)

        localStorage.setItem('token', response.data.access_token);

        dispatch(login({
            name: response.data.name
        }))

    } catch (error) {

        error.response
            ?
            /* Swal.fire({
                 type: 'error',
                 title: error.response.data.message,
                 showConfirmButton: false,
                 icon: 'error',
                 timer: 1500
             })*/
            toast.error(error.response.data.message, {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })
            :
            /* Swal.fire({
                 type: 'error',
                 title: 'Error inesperado',
                 icon: 'error',
                 showConfirmButton: false,
                 timer: 1500
             })*/
            toast.error("Error inesperado", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })
    }


}

const login = (user) => ({
    type: types.authLogin,
    payload: user
});

/*Al recargar la pagina comprebo si ya estoy logueado*/
export const startCheckIsAuthenticated = () =>  async (dispatch) => {

        /*si no tengo token en el localstorage directamente pongo el checking de la store en false
        * (el checking seria como un cargando )*/
        if (!localStorage.getItem('token')) {
             dispatch(checkingFinish());
             return

        }

        try {

            const response = await routes.auth.checkAuthenticated(localStorage.getItem('token'))
            /*Si el token del localstorage al cargar la pagina es el correcto
            * hago dispatch del reducer login, que pondrá en el store el name del usu
            * que me devuelve el checkAuthenticated, entonces al cambiar el store y ver que hay un name
            * el router mirar asi estamos en una ruta publica o en una privada, si hay name nos reedigira
            * a la ruta privada (el inicio) y si no hay name significara que no estamos logueados y nos reedirigira
            * a la ruta publica (el login) */
             dispatch(login({
                name: response.data.user.name
            }))
        } catch (error) {
             dispatch(checkingFinish());
             return 'no auth'

        }

}

const checkingFinish = () => ({type: types.authCheckingFinish});


export const startLogout = () => {
    return (dispatch) => {

        localStorage.clear();
        dispatch(logout());
    }
}

const logout = () => ({type: types.authLogout})

