import { types } from '../../types/types';

const initialState = {
    loading: false,
}

export const uiReducer = ( state = initialState, action ) => {

    switch ( action.type ) {

        case types.uiOpenLoading:
            return {
                loading: true
            }
        case types.uiCloseLoading:
            return {
                loading: false
            }
        default:
            return state;
    }

}


