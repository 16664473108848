import React, { useState} from 'react';
import logo from '../../../assets/images/logo.png'

import DropDown from "../dropdown";
import NavbarMenu from "./navbar";
import './style.scss';
import {Link, NavLink} from "react-router-dom";

function Header() {
    const [menuOpen, setMenuOpen] = useState('collapse')



    function renderMenu() {
        return (
            <>

                <li className="nav-item" ><NavLink exact to={'/'} className="nav-link" activeClassName="active active"><i
                    className="fe fe-file-text"/> Inicio</NavLink></li>
                <li className="nav-item" ><NavLink exact to={'/clientes'} className="nav-link" activeClassName="active active"><i className="fe fe-users"/> Clientes</NavLink>
                </li>
                <li className="nav-item" ><NavLink exact to={'/proveedores'} className="nav-link" activeClassName="active active"><i
                    className="fe fe-briefcase"/> Proveedores</NavLink></li>
                <li className="nav-item" ><NavLink exact to={'/presupuestos'} className="nav-link" activeClassName="active active"><i
                    className="fe fe-file-text"/> Presupuestos</NavLink></li>
                <li className="nav-item" ><NavLink exact to={'/facturas'} className="nav-link" activeClassName="active active"><i
                    className="fe fe-file-text"/> Facturas</NavLink></li>
                <li className="nav-item" ><NavLink exact to={'/articulos-capitulos'} className="nav-link" activeClassName="active active"><i
                    className="fe fe-book-open"/> Articulos</NavLink></li>
            </>
        )
    }



        return (
            <header className="Header">
                <div className="header">
                    <div className="container">
                        <div className="d-flex align-items-center"><a className="header-brand" href="/"><img src={logo}
                                                                                                             className="header-brand-img"
                                                                                                             alt="Tabler React"/></a>
                            <div className="d-flex order-lg-2 ml-auto align-items-center">

                                <DropDown/>
                            </div>
                            <a className="header-toggler d-lg-none ml-3 ml-lg-0" onClick={()=>{ menuOpen=='' ? setMenuOpen('collapse') : setMenuOpen('')}}><span
                                className="header-toggler-icon"/></a></div>

                        </div>
                    </div>

               <NavbarMenu menu={renderMenu()}/>

            </header>

    );


    }

    export default Header;