import React from 'react';

import {Backdrop, CircularProgress, makeStyles} from "@material-ui/core";
import { useDispatch, useSelector, useStore} from "react-redux";
import {startCloseLoading} from "../../../redux/actions/ui";


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function BackdropMod() {
    const classes = useStyles();
    const {loading} = useSelector(state => state.ui)
    const dispatch = useDispatch();

    return (
        <Backdrop  className={classes.backdrop}  open={loading} onClick={ ()  => dispatch(startCloseLoading())}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default BackdropMod;