import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import '../modal-crear-datatable-row/style.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";

export default function ModalEditarDatatableRow(props) {


    return (
        <div >

            <Dialog open={props.openModal} onClose={() => props.setOpenModal(false)} className="Modal-crear" aria-labelledby="form-dialog-title">
                <DialogTitle  className="position-relative">
                    {props.title}
                    <FontAwesomeIcon style={{color: '#333', fontSize: '20px', position:'absolute', cursor: 'pointer', right:'15px' , top:'25px'}} onClick={() => props.setOpenModal(false)} icon={faTimes}/>

                </DialogTitle>
                <DialogContent>

                    {/*   <DialogContentText>
                        {props.subtitle}
                    </DialogContentText>*/}
                    {props.form}

                </DialogContent>

            </Dialog>
        </div>
    );
}