
import React from 'react';
import CrearFactura from "../components/facturas/crear-factura";
import InicioGrupo2 from "../components/GRUPO2/inicio-grupo2";
import Trabajos from "../components/GRUPO2/trabajos";
import Clientes from "../components/GRUPO2/clientes";
import Obras from "../components/GRUPO2/obras";
import DocumentosObra from "../components/GRUPO2/documentos-obra";
import Cuentas from "../components/GRUPO2/cuentas";
import { Switch, Route, Redirect } from 'react-router-dom';
import Header from "../components/commons/header";
import Facturas from "../components/facturas";
import EditarFactura from "../components/facturas/editar-factura";
import {PublicRoute} from "./PublicRoute";
import CambiarEstadoFactura from "../components/facturas/cambiar_estado_factura";
import Presupuestos from "../components/presupuestos";
import ArticulosCapitulos from "../components/articulos";
import CrearPresupuesto from "../components/presupuestos/crear-presupuesto";
import EditarPresupuesto from "../components/presupuestos/editar-presupuesto";
import Dashboard from "../components/dashboard";
import ArticulosPartidas from "../components/articulos/articulos_partidas";
/*
import Presupuestos from "../components/presupuestos";
import CrearPresupuesto from "../components/presupuestos/crear-presupuesto";
import EditarPresupuesto from "../components/presupuestos/editar-presupuesto";
*/

function DashboardRoutes() {

    return (
        <>
            <Header/>
            <main className="Main">
                <Route exact path='/facturas' component={Facturas}/>
                <Route exact path='/crear-factura' component={CrearFactura}/>
                <Route exact path='/editar-factura/:id_factura' component={EditarFactura}/>


                <Route exact path='/presupuestos' component={Presupuestos}/>
                <Route exact path='/crear-presupuesto' component={CrearPresupuesto}/>
                <Route exact path='/editar-presupuesto/:id_presupuesto' component={EditarPresupuesto}/>


                <Route exact path='/clientes' component={Clientes}/>
                <Route exact path='/articulos-capitulos' component={ArticulosCapitulos}/>
                <Route exact path='/articulos-partidas/:id_capitulo' component={ArticulosPartidas}/>


               {/* <Route exact path='/' component={InicioGrupo2}/>*/}
                <Route exact path='/' component={Dashboard}/>
                <Route exact path='/trabajos' component={Trabajos}/>
                <Route exact path='/trabajos/:anyo' component={Clientes}/>
                <Route exact path='/trabajos/:anyo/obras' component={Obras}/>
                <Route exact path='/trabajos/:anyo/obras/documentos' component={DocumentosObra}/>
                <Route exact path='/cuentas' component={Cuentas}/>
                <Route exact path='/cuentas/:anyo' component={InicioGrupo2}/>
            </main>
        </>
    );
}

export default DashboardRoutes;
