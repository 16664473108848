import React from 'react';

import Datatable from "../commons/datatables/datatable";
import moment from 'moment';
import {useForm } from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";
import {Link, useHistory} from "react-router-dom";
import {Chip, CircularProgress} from "@material-ui/core";
import {useMutation, useQuery} from "react-query";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {Controller, useFormContext} from "react-hook-form";
import {MenuItem} from "@material-ui/core";


import routes from "../../services/api";

import {
    faCopy, faDollarSign,
    faEnvelope,
    faFileInvoiceDollar,
    faFilePdf,
    faPencilAlt,
    faPlus,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import Button from "@material-ui/core/Button";
import {calcularPrecioMasIva, euroFormat} from "../../helpers/generales";
import {startCloseLoading, startOpenLoading} from "../../redux/actions/ui";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import Swal from 'sweetalert2';
import ModalCrearDatatableRow from "../commons/modal-crear-datatable-row";
import ModalSinBoton from "../commons/modal-sin-boton";


function TablaFacturas(props) {
    let history = useHistory();
    const dispatch = useDispatch();


    // Queries

    // Queries
    /* const query = useQuery('todos', getTodos)
 */
    const {status, data, error, isFetching, refetch} = useQuery("getAllFacturas", async () => {
        const res = await routes.facturas.getAll()
        return res.data;
    }, {refetchOnWindowFocus: false});
    const {register, handleSubmit, control,setValue} = useForm()

    const mutation_duplicar_factura = useMutation((id) => routes.facturas.duplicar_factura({id:id}), {
        onSuccess: (response) => {
            dispatch(startCloseLoading())

            toast.success("Factura duplicada correctamente", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })
            refetch()


        },
        onError: (error) => {
            dispatch(startCloseLoading())

            toast.error("Error al crear factura", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })


        }
    })
    const [openModal, setOpenModal] = React.useState(false);
    const [idFacestad, setidFacestad] = React.useState(0);

    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: true,
            }
        },

        {
            name: "numero_factura",
            label: "Número",
            options: {
                filter: true,
                sort: true,

            }

        },

        {
            name: "cliente",
            label: "Cliente",
            options: {
                filter: true,
                sort: true,

            }
        },

        {
            name: "fecha",
            label: "Fecha Creación",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {

                    return (
                        <> {value ? moment(value).format('DD-MM-YYYY') : ''}</>
                    );
                }
            },

        },
        {
            name: "fecha_envio",
            label: "Fecha envio",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value) => {

                    return (
                        <> {value ? moment(value).format('DD-MM-YYYY') : ''}</>
                    );
                }
            }

        },
        {
            name: "obra",
            label: "Obra",
            options: {
                filter: true,
                sort: true,

            }

        },
        {
            name: "base_imponible_con_descuentos",
            label: "Base imponible",

            options: {
                filter: true,
                sort: false,
                /*display: false,*/
                customBodyRender: (value) => {

                    return (
                        <> {euroFormat(value)}</>
                    );
                }
            }

        },
        {
            name: "iva",
            label: "Iva",

            options: {
                filter: true,
                sort: false,
                /*display: false,*/
                customBodyRender: (value) => {

                    return (
                        <> {value + ' %'}</>
                    );
                }
            }

        },
        {
            name: "precio_total_con_descuentos",
            label: "Total",

            options: {
                filter: true,
                sort: false,
                /*display: false,*/
                customBodyRender: (value, tableMeta) => {
                    return (
                        <> {euroFormat(value)}</>

                    );
                }
            }

        },
        {
            name: "estado",
            label: "Estado",

            options: {
                filter: true,
                sort: false,
                /*display: false,*/
                customBodyRender: (value, tableMeta, updateValue) => {
                    let bg = 'gray'
                    switch (value) {
                        case 'vista':
                            bg = '#303f9f'
                            break;
                        case 'rechazada':
                            bg = '#c51162'
                            break;
                        case 'aceptada':
                            bg = '#0ed30e'
                            break;
                        case 'enviada':
                            bg = '#d39b0e'
                            break;
                        case 'cobrada':
                            bg = '#5ad30e'
                            break;
                    }
                    return (
                        <div className="d-flex justify-content-center text-center">
                            <Chip label={value} variant="outlined"  style={{border: 'none' ,textTransform: 'capitalize', color: 'white', backgroundColor: bg}}/>

                        </div>
                    );
                }
            }

        },
        {
            name: "email_enviar",
            label: "Email cliente",

            options: {
                filter: true,
                sort: false,
                display: false,

            }

        },
        {
            name: "uuid",
            label: "uuid",
            options: {
                filter: false,
                sort: true,
                display: false
            }
        },
        {
            name: "ver",
            label: 'Ver obras',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {

                    return (
                        <>
                            <button title="Editar Factura" onClick={() => {


                                history.push('/editar-factura/' + tableMeta.rowData[0])


                            }} style={{color: '#333', border: 'none', background: 'transparent'}}

                                    className="Btn-transparent-border-green Btn-small">

                                <FontAwesomeIcon style={{color: '#333', fontSize: '20px'}} icon={faPencilAlt}/>

                            </button>

                            <a title="Descargar PDF" target="_blank"
                               href={`http://api.facturacion.grepol2.es/api/facturas/factura_pdf/${tableMeta.rowData[11]}`}
                               style={{color: '#333', border: 'none', background: 'transparent'}}

                               className="Btn-transparent-border-green Btn-small">

                                <FontAwesomeIcon style={{color: '#333', fontSize: '20px'}} icon={faFilePdf}/>

                            </a>
                            <button title="Enviar Factura" onClick={() => {

                                Swal.fire({
                                    title: 'Seguro que quieres enviar la factura?',
                                    html: "<p class='my-3'>Se enviará a: " + tableMeta.rowData[10] + "</p>",
                                    icon: 'question',
                                    width: 700,
                                    maxWidth: '90%',
                                    padding: '3em',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Enviar'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        dispatch(startOpenLoading())

                                        mutation_enviar_factura.mutate(tableMeta.rowData[11])
                                    }
                                })


                            }} style={{color: '#333', border: 'none', background: 'transparent'}}

                                className="Btn-transparent-border-green Btn-small">

                                <FontAwesomeIcon style={{color: '#333', fontSize: '20px'}} icon={faEnvelope}/>

                                </button>


                               {/* <button onClick={() => {


                                history.push('/trabajos/' + props.anyo + '/obras')

                            }} style={{color: '#333', border: 'none', background: 'transparent'}}

                                className="Btn-transparent-border-green Btn-small">


                                <FontAwesomeIcon style={{color: '#333', fontSize: '20px'}} icon={faArrowRight}/>


                                </button>*/}
                            <button title="Duplicar Factura" onClick={() => {

                                Swal.fire({
                                    title: 'Seguro que quieres duplicar la factura?',
                                    html: "<p class='my-3'></p>",
                                    icon: 'question',
                                    width: 700,
                                    maxWidth: '90%',
                                    padding: '3em',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Confirmar'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        dispatch(startOpenLoading())

                                        mutation_duplicar_factura.mutate(tableMeta.rowData[0])
                                    }
                                })


                            }} style={{color: '#333', border: 'none', background: 'transparent'}}

                                    className="Btn-transparent-border-green Btn-small">

                                <FontAwesomeIcon style={{color: '#333', fontSize: '20px'}} icon={faCopy}/>

                            </button>
                            <button title="Cambiar Estado Factura" onClick={() => {

                                setidFacestad(tableMeta.rowData[0])

                                setOpenModal(true)


                            }} style={{color: '#333', border: 'none', background: 'transparent'}}

                                    className="Btn-transparent-border-green Btn-small">

                                <FontAwesomeIcon style={{color: '#333', fontSize: '20px'}} icon={faDollarSign}/>

                            </button>
                            <button title="Eliminar Factura" onClick={() => {

                                Swal.fire({
                                    title: 'Seguro que quieres eliminar la factura?',
                                    html: "<p class='my-3'>Se eliminara la factura con núm: " + tableMeta.rowData[1] + "</p>",
                                    icon: 'question',
                                    width: 700,
                                    maxWidth: '90%',
                                    padding: '3em',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Eliminar'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        dispatch(startOpenLoading())

                                        mutation_delete_factura.mutate(tableMeta.rowData[0])
                                    }
                                })


                            }} style={{color: '#333', border: 'none', background: 'transparent'}}

                                    className="Btn-transparent-border-green Btn-small">

                                <FontAwesomeIcon style={{color: 'red', fontSize: '20px'}} icon={faTimes}/>

                            </button>
                                </>
                                );
                            }
            },

        }
    ];

    const options = {
        selectableRows: 'none',


    }

    const mutation_enviar_factura = useMutation((id) => routes.facturas.enviar_factura(id), {
        onSuccess: (response) => {
            dispatch(startCloseLoading())

            toast.success("Factura enviada correctamente", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })

            let refetched = refetch()


        },
        onError: (error) => {
            dispatch(startCloseLoading())

            toast.error("Error al enviar factura", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })


        }
    })
    const mutation_delete_factura = useMutation((id) => routes.facturas.delete({id:id}), {
        onSuccess: (response) => {
            dispatch(startCloseLoading())

            toast.success("Factura eliminada correctamente", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })

            let refetched = refetch()


        },
        onError: (error) => {
            dispatch(startCloseLoading())

            toast.error("Error al eliminar factura", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })


        }
    })

    function FormCambiarEstado(submitFunction , register_param, control_param , setOpenModal_param,mutation_param){
        return (
            <form onSubmit={submitFunction}>
                <input type="hidden" ref={register_param} name={`id`} />
                <div className="Grid">
                    <FormControl fullWidth className="mt-2">
                        <InputLabel >
                            Estado
                        </InputLabel>
                        <Controller
                            control={control_param}
                            name={`estado`}
                            margin="dense"
                            defaultValue=""
                            as={
                                <Select defaultValue="">
                                    <MenuItem value="cobrada">
                                        Cobrada
                                    </MenuItem>
                                    <MenuItem value="contabilizada">
                                        Contabilizada
                                    </MenuItem>
                                    <MenuItem value="rechazada">
                                        Rechazada
                                    </MenuItem>
                                    <MenuItem value="enviada">
                                        Enviada
                                    </MenuItem>
                                    <MenuItem value="aceptada">
                                        Aceptada
                                    </MenuItem>
                                    <MenuItem value="vista">
                                        Vista
                                    </MenuItem>
                                </Select>
                            }
                        />
                    </FormControl>




                </div>


                <div className="d-flex " style={{marginTop: '50px'}}>
                    <Button variant="contained" color="secondary" onClick={() => setOpenModal_param(false)} type="button"
                            className="mr-auto d-block my-5">
                        Cancelar
                    </Button>

                        <Button variant="contained" color="primary" type="submit" className="ml-auto d-block my-5">
                            Guardar

                        </Button>
                </div>
            </form>
        )
    }
    const mutation_cambiar_estado = useMutation((data) => routes.facturas.cambiar_estado_manual(data), {
        onSuccess: (response) => {
            toast.success("Estado  cambiado correctamente", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })
            setOpenModal(false)
            refetch()
        },
        onError: (error) => {
            toast.error("Error al cambiar estado ", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            })
            setOpenModal(false)

        },
        onMutate: () => {

        }
    })

    const onSubmit = data => {
        data.id= idFacestad
        mutation_cambiar_estado.mutate(data)
    }
    return (
        <div className="mw-100">
            <ModalSinBoton title={'Cambiar estado factura'} openModal={openModal} setOpenModal={setOpenModal}
                                    form={FormCambiarEstado(handleSubmit(onSubmit),register,control,setOpenModal,mutation_cambiar_estado)}/>
            {
                !isFetching &&
                <Link to={'/crear-factura'} style={{color: 'white'}}>
                    <Button variant="contained" color="primary" className="Btn-modal-crear" type='button' style={{marginTop: "-48px",marginBottom:'10px'}}>
                        Crear Nueva Factura
                    </Button>
                </Link>
            }

            {data && !isFetching && !error && <Datatable titulo={''} data={data} options={options} columns={columns}/>}
            {
                !isFetching && !error && !data && <h3 className="text-center my-5 py-5 ">No hay datos</h3>
            }
            {
                isFetching &&
                <div className="d-flex justify-content-center align-items-center my-5 py-5"><CircularProgress/></div>

            }
            {
                error &&
                <h3 className="text-center my-5 py-5 " style={{color: 'red'}}>Ha ocurrido un error {error.message}</h3>
            }

        </div>
    );


}


export default TablaFacturas;