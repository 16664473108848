import React, {createContext, useReducer} from "react";

export const NavigationContext = createContext(null);

const initialNavigation = {
    anyo : null,
    nombre_cliente : null,
    nombre_obra : null,
};

function NavigationReducer(state, action) {
    switch (action.type) {
        case 'update':
            return {
                ...state,
                ...action.data
            };

        default:
            return state
    }
}

const NavigationProvider = props => {
    const [navigation_state, navigation_dispatch] = useReducer(NavigationReducer, initialNavigation);

    return (
        <NavigationContext.Provider value={{navigation_state, navigation_dispatch}}>
            {props.children}
        </NavigationContext.Provider>
    );
};

export default NavigationProvider;