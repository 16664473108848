import {types} from '../../types/types';




export const startOpenLoading = () => {
    return  (dispatch) => {

        dispatch(openLoading());
    }
}

const openLoading = () => ({type: types.uiOpenLoading})


export const startCloseLoading = () => {
    return (dispatch) => {

        dispatch(closeLoading());
    }
}

const closeLoading = () => ({type: types.uiCloseLoading})




